import { createContext, ReactNode, useEffect, useState, FC, useContext, useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { notifyError, notifySuccess } from '../helpers/utils';
import { addSourceFileInSession, fetchSourceFiles, getMappingSession } from '../services/apiCalls';
import _ from 'lodash';

import {
  IGeneralizationContext,
  GeneralizationSuggestion,
  GeneralizationDocument,
  AIResponse,
  GeneralizationSuggestionMap,
  MappingTypeEnum,
  ICategory,
  IGroupingVariables,
} from '../types';
import {
  gvCategoriesArray,
  clearHighlightedElements,
  fixTablesFormatting,
  getAllCells,
} from '../helpers/generalizationHelpers';
import { CatalogContext } from './CatalogContext';
import { MappingContext } from './MappingContext';

export const defaultGeneralizationContext: IGeneralizationContext = {
  gvCategories: [],
  setGVCategories: () => {},
  acceptedSuggestions: [],
  aiResponse: undefined,
  generatedDocument: undefined,
  sourceDocuments: undefined,
  selectedCatalogId: -1,
  setLoadingSourceDocument: () => {},
  loadingSourceDocument: false,
  setSelectedCatalogId: () => {},
  setAcceptedSuggestions: () => {},
  loadingGeneratedDocument: false,
  setGeneratedDocumentWithAcceptedSuggestions: (_doc: Document) => {},
  sessionId: undefined,
  getSourceNodeIndex: (
    _targetNodeId: string,
    _aiRes?: AIResponse | undefined,
    _sourceNodeId?: string | null,
  ): number => {
    return 0;
  },
  setAiResponse: () => undefined,
  setSourceDocuments: () => {},
  handleSourcechange: () => {},
  handleAddSourceFiles: () => {},
  selectedValue: '',
  setSelectedValue: () => {},
  openSourceModal: () => {},
  closeSourceModal: () => {},
  sourceModalOpen: false,
  setSourceModalOpen: () => {},
  handleGetSourceContent: (_id: number) => {},
  lastSaved: new Date(),
  setLastSaved: () => {},
  authors: [],
  validators: [],
  setValidators: () => {},
  mappingType: MappingTypeEnum.AUTHORING,
  mappingSessionTitle: '',
  selectionSearch: true,
  setSelectionSearch: () => {},
  searchTerm: '',
  setSearchTerm: () => {},
  clearAISuggestions: false,
  setClearAISuggestions: () => {},
  aiResponseCopy: undefined,
  setAiResponseCopy: () => undefined,
  selectedSourceFileName: '',
  setSelectedSourceFileName: () => {},
  cells: [],
  setCells: () => {},
  lockSourceFile: false,
  setLockSourceFile: () => {},
  targetNodeIdSuggestionMap: {},
  sourceNodeIdSuggestionMap: {},
  isParsed: true,
  setGroupingVariables: () => {},
  groupingVariables: [],
  resetStates: () => {},
  isOriginalAvailable: false,
  sanitizeAiResponse: () => ({}) as AIResponse,
};

export const GeneralizationContext = createContext<IGeneralizationContext>(
  defaultGeneralizationContext,
);

interface Props {
  children: ReactNode;
}

const GeneralizationContextContainer: FC<Props> = ({ children }) => {
  const [loadingGeneratedDocument, setLoadingGeneratedDocument] = useState<boolean>(
    defaultGeneralizationContext.loadingGeneratedDocument,
  );
  const [loadingSourceDocument, setLoadingSourceDocument] = useState<boolean>(
    defaultGeneralizationContext.loadingGeneratedDocument,
  );
  const [sessionId, setSessionId] = useState<number | undefined>();
  const [acceptedSuggestions, setAcceptedSuggestions] = useState<GeneralizationSuggestion[]>();
  const [aiResponse, setAiResponse] = useState<AIResponse | undefined>();
  const [initialTargetDocument, setInitialTargetDocument] = useState<GeneralizationDocument>();
  const [generatedDocument, setGeneratedDocument] = useState<GeneralizationDocument>();
  const [sourceDocuments, setSourceDocuments] = useState<Map<string, GeneralizationDocument>>();
  const [selectedCatalogId, _setSelectedCatalogId] = useState<number>(0);
  const [lastSaved, setLastSaved] = useState<Date>();
  const [authors, setAuthors] = useState<string[]>([]);
  const [validators, setValidators] = useState<string[]>([]);
  const [mappingType, setMappingType] = useState(MappingTypeEnum.AI_AUTHORING);
  const [mappingSessionTitle, setMappingSessionTitle] = useState<string>('');
  const [selectionSearch, setSelectionSearch] = useState(false);
  const [lockSourceFile, setLockSourceFile] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [clearAISuggestions, setClearAISuggestions] = useState(false);
  const [aiResponseCopy, setAiResponseCopy] = useState<AIResponse | undefined>();
  const [selectedSourceFileName, setSelectedSourceFileName] = useState<string>('');
  const [groupingVariables, setGroupingVariables] = useState<string[]>([]);
  const [selectedSourceDoc, setSelectedSourceDoc] = useState<GeneralizationDocument>();
  const [gvCategories, setGVCategories] = useState<ICategory[]>([]);
  const [isTargetInitialized, setIsTargetInitialized] = useState(false);
  const [isParsed, setIsParsed] = useState(defaultGeneralizationContext.isParsed);

  const [cells, setCells] = useState<string[]>([]);
  const match = useMatch('/mapping-sessions/:id/*');

  const resetStates = () => {
    setAcceptedSuggestions(defaultGeneralizationContext.acceptedSuggestions);
    setAiResponse(defaultGeneralizationContext.aiResponse);
    setGeneratedDocument(defaultGeneralizationContext.generatedDocument);
    setSourceDocuments(defaultGeneralizationContext.sourceDocuments);
    setIsTargetInitialized(false);
    setSourceFileDoc({});
  };

  const { targetNodeIdSuggestionMap, sourceNodeIdSuggestionMap } = useMemo(() => {
    const targetNodeIdMap: GeneralizationSuggestionMap = {};
    const sourceNodeIdMap: GeneralizationSuggestionMap = {};

    acceptedSuggestions?.forEach((suggestion) => {
      targetNodeIdMap[suggestion.targetNodeId] = suggestion;
      if (suggestion.sourceNodeId) {
        sourceNodeIdMap[suggestion.sourceNodeId] = suggestion;
      }
    });

    return {
      targetNodeIdSuggestionMap: targetNodeIdMap,
      sourceNodeIdSuggestionMap: sourceNodeIdMap,
    };
  }, [acceptedSuggestions]);

  const getSourceNodeIndex = (
    targetNodeId: string,
    aiRes: AIResponse | undefined = aiResponse,
    sourceNodeId: string | null = null,
  ): number => {
    const acceptedSuggestion = targetNodeIdSuggestionMap[targetNodeId];
    if (!acceptedSuggestion) {
      return 0;
    }

    const sourceId = sourceNodeId ?? acceptedSuggestion.sourceNodeId;
    const index = _.findIndex(aiRes?.[targetNodeId], (o) => _.has(o, sourceId));
    return index;
  };

  const processHTML = (originalHtml: string) => {
    const filteredHtml = originalHtml.replaceAll('.html[0].body[1]', '');
    return fixTablesFormatting(filteredHtml);
  };

  useEffect(() => {
    match?.params.id && setSessionId(+match?.params.id);

    return () => setSessionId(undefined);
  }, [match]);

  const setGeneratedDocumentWithAcceptedSuggestions = (documnet: Document | null = null) => {
    if (!initialTargetDocument || !acceptedSuggestions) return;
    const parser = new DOMParser();

    if (mappingType === 'ai-authoring')
      initialTargetDocument.html = clearHighlightedElements(initialTargetDocument.html);

    acceptedSuggestions.forEach((suggestion) => {
      const doc = documnet || parser.parseFromString(initialTargetDocument.html, 'text/html');
      const generatedDocumentNode = doc.querySelector(
        `[data-nodeid='${
          suggestion.targetNodeId
        }'],[data-nodeid='${suggestion?.targetNodeId?.replace('.html[0].body[1]', '')}']`,
      );
      if (generatedDocumentNode) {
        const cellElement =
          generatedDocumentNode.closest('td') || generatedDocumentNode.closest('th');
        if (
          (cellElement && cellElement.childNodes && cellElement.childNodes.length <= 1) ||
          suggestion.mapperType === 'ai'
        ) {
          const targetNode = cellElement || generatedDocumentNode;

          targetNode.setAttribute('data-nodeid', suggestion.targetNodeId);
          targetNode.innerHTML = suggestion.targetValue;
        } else {
          generatedDocumentNode.innerHTML = suggestion.targetValue;
        }
        initialTargetDocument.html = doc?.body?.outerHTML;
      }
    });

    setGeneratedDocument({ ...initialTargetDocument });
  };

  const setSourceDocumentWithAcceptedSuggestions = () => {
    if (!selectedSourceDoc || !aiResponse || !acceptedSuggestions) return;
    const parser = new DOMParser();
    const doc = parser.parseFromString(selectedSourceDoc.html, 'text/html');
    acceptedSuggestions.forEach((suggestion) => {
      const sourceFileDocNode = doc.querySelector(
        `[data-nodeid='${
          suggestion.sourceNodeId
        }'],[data-nodeid='${suggestion.sourceNodeId?.replace('.html[0].body[1]', '')}']`,
      );
      if (sourceFileDocNode && suggestion.sourceValue) {
        const tdElement = sourceFileDocNode.closest('td');
        const newNode = tdElement || sourceFileDocNode;
        newNode.setAttribute('data-nodeid', suggestion.sourceNodeId);
        newNode.innerHTML = suggestion.sourceValue;
        selectedSourceDoc.html = doc?.body?.innerHTML;
      }
    });
    setSourceFileDoc({ ...selectedSourceDoc });
  };

  useEffect(() => {
    setSourceDocumentWithAcceptedSuggestions();
  }, [selectedSourceDoc]);

  // TODO: update the type
  const sanitizeAiResponse = (aiResp: any) => {
    const sanitizedResponse: any = {};

    for (const key in aiResp) {
      const sanitizedKey = key.replace(/\.html\[0\]\.body\[1\]/g, '');
      const value = aiResp[key];

      if (Array.isArray(value)) {
        const sanitizedValue = value.map((item: any) => sanitizeAiResponse(item));
        sanitizedResponse[sanitizedKey] = sanitizedValue;
      } else {
        sanitizedResponse[sanitizedKey] = {
          ...value,
          value: _.unescape(value.value),
        };
      }
    }

    return sanitizedResponse;
  };

  useEffect(() => {
    if (aiResponse && Object.keys(aiResponse).length && !isTargetInitialized) {
      setIsTargetInitialized(true);
      setGeneratedDocumentWithAcceptedSuggestions();
    } else {
      setGeneratedDocumentWithAcceptedSuggestions(
        isTargetInitialized ? editor1Ref.current.getBody() : null,
      );
    }
  }, [aiResponse]);

  useEffect(() => {
    resetStates();
    if (sessionId) {
      let sourceId: number;
      setLoadingGeneratedDocument(true);
      getMappingSession(sessionId)
        .then((res) => {
          const groupingVariables: IGroupingVariables = res.data.groupingVariables;
          setGVCategories(gvCategoriesArray(groupingVariables));
          setMappingSessionTitle(res.data.template.filename);
          setMappingType(res.data.mappingType);
          sourceId = res.data.t2[0].id;
          const sanitizedAIResponse = sanitizeAiResponse(res.data.aiSuggestions);
          setAiResponse(sanitizedAIResponse);
          setAiResponseCopy(sanitizedAIResponse);
          setAcceptedSuggestions(
            res.data.acceptedSuggestions.map((s: GeneralizationSuggestion) => ({
              ...s,
              sourceFileId: s.sourceFile?.id,
            })),
          );
          setInitialTargetDocument({
            ...res.data.template,
            html: processHTML(res.data.template.html),
          });
          setSourceDocuments(
            res.data.t2.reduce(
              (accu: Map<string, GeneralizationDocument>, source: GeneralizationDocument) => {
                accu.set(`${source.id}`, {
                  id: source.id,
                  html: source.html,
                  filename: source.filename,
                  nonParsedCatalogId: source.nonParsedCatalogId,
                  version: source.version,
                });
                return accu;
              },
              new Map(),
            ),
          );
          _setSelectedCatalogId(sourceId);
          setSelectedValue(String(sourceId));
          handleGetSourceContent(sourceId);
          setLastSaved(res.data.lastUpdatedAt ? new Date(res.data.lastUpdatedAt) : new Date());
          setAuthors(res.data.authors || []);
          setValidators(res.data.validators || []);
          setGroupingVariables(res.data.groupingVariables || []);
        })
        .catch((err) => {
          console.error(err);
          notifyError('Documents Not Found');
        })
        .finally(() => setLoadingGeneratedDocument(false));
    }
  }, [sessionId]);

  const handleGetSourceContent = (currentSourceIds: number, getParsed: boolean | null = null) => {
    setLoadingSourceDocument(true);
    fetchSourceFiles([currentSourceIds], getParsed)
      .then(
        (res: {
          data: Array<{
            filename: string;
            html: string;
            id: number;
            isParsed: boolean;
            sourceDocNumber: string;
            version: number;
          }>;
        }) => {
          setSelectedSourceDoc(res.data[0]);
          setIsParsed(res.data[0].isParsed);
        },
      )
      .catch((_err) => {
        notifyError('Error in fetching source content');
      })
      .finally(() => {
        setLoadingSourceDocument(false);
      });
  };

  const { selectedSourceDocuments, setSelectedSourceDocuments } = useContext(CatalogContext);
  const { setSourceFileDoc, editor1Ref } = useContext(MappingContext);
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    setCells(getAllCells(initialTargetDocument?.html || ''));
  }, [initialTargetDocument]);

  const openSourceModal = () => {
    setSelectedSourceDocuments([]);
    setSourceModalOpen(true);
  };
  const closeSourceModal = () => {
    setSelectedSourceDocuments([]);
    setSourceModalOpen(false);
  };
  const [sourceModalOpen, setSourceModalOpen] = useState(false);

  const handleAddSourceFiles = async () => {
    if (sourceDocuments) {
      const sourceDocumentsKeys = Array.from(sourceDocuments.keys()).map(Number);
      const selectedSourceIds = selectedSourceDocuments.filter(
        (element: any) => !sourceDocumentsKeys.includes(element),
      );
      addSourceFileInSession(selectedSourceIds, sessionId)
        .then((res) => {
          const newData = res.data.reduce(
            (accu: any, sourceFile: any) => {
              accu.set(`${sourceFile.id}`, {
                id: sourceFile.id,
                html: sourceFile.html,
                filename: sourceFile.filename,
                nonParsedCatalogId: sourceFile.nonParsedCatalogId,
              });
              return accu;
            },
            new Map([...sourceDocuments]),
          );

          setSourceDocuments(newData);
          notifySuccess('Source files added successfully!');
          closeSourceModal();
        })
        .catch((err) => {
          console.error(err);
          notifyError('something went wrong!');
        });
    }
  };

  const setSelectedCatalogId = (catalogId: number) => {
    if (!sourceDocuments) return;

    if (sourceDocuments.get(`${catalogId}`)) {
      _setSelectedCatalogId(+catalogId);
    } else {
      const x = Array.from(sourceDocuments.values()).find(
        (value) => value.nonParsedCatalogId === catalogId,
      )?.id;
      if (!x) return;
      _setSelectedCatalogId(x);
    }
  };

  const handleSourcechange = (value: string, getParsed: boolean | null = null) => {
    +value && handleGetSourceContent(+value, getParsed);
    _setSelectedCatalogId(+value);
    setSelectedValue(value);
  };

  const nonParsedCatalogIdExistForSelectedCatalog = useMemo(() => {
    const catalogInfo = sourceDocuments?.get(`${selectedCatalogId}`);
    if (!catalogInfo) return true;
    return !!catalogInfo?.nonParsedCatalogId;
  }, [selectedCatalogId, sourceDocuments]);

  const isOriginalAvailable =
    nonParsedCatalogIdExistForSelectedCatalog && !loadingGeneratedDocument;

  return (
    <GeneralizationContext.Provider
      value={{
        loadingGeneratedDocument,
        acceptedSuggestions,
        aiResponse,
        generatedDocument,
        sourceDocuments,
        setAcceptedSuggestions,
        getSourceNodeIndex,
        setAiResponse,
        sessionId,
        setSourceDocuments,
        handleSourcechange,
        handleAddSourceFiles,
        selectedValue,
        setSelectedValue,
        openSourceModal,
        closeSourceModal,
        sourceModalOpen,
        setSourceModalOpen,
        handleGetSourceContent,
        selectedCatalogId,
        setSelectedCatalogId,
        setLoadingSourceDocument,
        loadingSourceDocument,
        setGeneratedDocumentWithAcceptedSuggestions,
        lastSaved,
        setLastSaved,
        authors,
        validators,
        setValidators,
        mappingType,
        mappingSessionTitle,
        searchTerm,
        setSearchTerm,
        selectionSearch,
        setSelectionSearch,
        aiResponseCopy,
        setAiResponseCopy,
        clearAISuggestions,
        setClearAISuggestions,
        selectedSourceFileName,
        setSelectedSourceFileName,
        cells,
        setCells,
        lockSourceFile,
        setLockSourceFile,
        targetNodeIdSuggestionMap,
        sourceNodeIdSuggestionMap,
        isParsed,
        setGroupingVariables,
        groupingVariables,
        gvCategories,
        setGVCategories,
        resetStates,
        isOriginalAvailable,
        sanitizeAiResponse,
      }}>
      {children}
    </GeneralizationContext.Provider>
  );
};

export default GeneralizationContextContainer;
