import { FC } from 'react';
import { Search, Button, Dropdown } from '@gloabal-regulatory-writing-consulting/gxt-components';
import SvgIcon from '../../../../components/elements/SvgIcon';
import { CatalogActionBarProps } from './CatalogActionBar.types';
import { getDropdownOptions } from '../../../../helpers/utils';

const CatalogActionBar: FC<CatalogActionBarProps> = ({ handleSearch, buttons, templateType }) => {
  return (
    <div className="flex w-full gap-2">
      {handleSearch && <Search onChangeCallback={handleSearch} width="100%" className="h-full" />}
      {buttons?.map(({ type, options = [], ...button }) => (
        <div key={button.id}>
          {type === 'select' ? (
            <Dropdown
              options={getDropdownOptions(options)}
              type={type}
              dropdownIcon
              initialValue={{ value: templateType }}
              renderOption={(item) => item.value.title}
              position="bottom"
              onSelect={(option) => button.onSelect && button.onSelect(option.value)}
              customStyles={{
                container: { height: '100%' },
                button: { whiteSpace: 'nowrap', height: '100%' },
                itemsWrapper: { zIndex: 100, width: '100%' },
              }}
              equalityFn={(a, b) => a?.value === b?.value}
            />
          ) : (
            <Button
              variant={button.variant}
              onClick={button.onClick}
              disabled={button.disabled}
              id={button.id}
              data-testid={button.dataTestId}
              className={button.styles}>
              {button.title}
              {button.iconType && (
                <SvgIcon
                  date-testid={button.iconType || 'svg'}
                  iconType={button.iconType}
                  stroke={button.disabled ? 'var(--neutral-200, #9CA3AF)' : undefined}
                />
              )}
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

export default CatalogActionBar;
