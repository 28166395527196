// React-related imports
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import ReactLoading from 'react-loading';

// Contexts and Hooks
import useAsyncOperation from '../../hooks/useAsyncOperation';

// Components
import ModalInputFields from '../modals/modalInputFields';

// Helpers
import { notifyError, notifySuccess } from '../../helpers/utils';

// Types
import { MappingSessionRecord } from '../../types';

// Other dependencies
import { convertToAutomapping } from '../../services/apiCalls';
import { modalCustomStyles } from '../../constants';

type ModalInputField = {
  label: string;
  placeholder: string;
  type: string;
  name: string;
  inputClassName: string;
  disabled: boolean;
  value: string;
};

type ConvertToAutoMappingModalProps = {
  handleClose: () => void;
  show: boolean;
  mappingSessionData: MappingSessionRecord | null;
};

const ConvertToAutoMappingModal: React.FC<ConvertToAutoMappingModalProps> = ({
  handleClose,
  show,
  mappingSessionData,
}) => {
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [formData, setFormData] = useState({ title: '' });
  const navigate = useNavigate();

  useEffect(() => {
    setFormData({ title: mappingSessionData?.title || '' });
  }, [mappingSessionData]);

  const fileNameModalInputList: ModalInputField[] = [
    {
      label: 'Document Title',
      placeholder: 'Enter title',
      type: 'text',
      name: 'title',
      inputClassName: 'w-full',
      disabled: false,
      value: formData.title,
    },
  ];

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = (data: { [key: string]: string }) => {
    const newObj: Record<string, boolean> = {};
    Object.entries(data).forEach(([key, value]) => {
      if (!value) {
        newObj[key] = true;
      }
    });
    return newObj;
  };

  const handleConvertToAutomapping = async () => {
    const mappingSessionId = mappingSessionData?.id;
    const result = validateForm(formData);
    if (Object.keys(result).length > 0) {
      return setErrors(result);
    }
    setErrors({});
    try {
      const res = await convertToAutomapping(mappingSessionId, {
        title: formData.title,
      });
      notifySuccess(res.data.message);
      navigate('/catalog-list');
    } catch (error: any) {
      notifyError(error?.message || 'Something went wrong while converting to automapping');
      console.error(error);
    }
  };
  const { isLoading: isSaving, executeCallback: handleConvert } = useAsyncOperation(
    handleConvertToAutomapping,
  );

  return (
    <Modal isOpen={show} onRequestClose={handleClose} style={modalCustomStyles} ariaHideApp={false}>
      <h1 className="font-bold text-center p-4 text-xl">AutoMapping Session</h1>
      <div className="space-y-8 sm:p-8">
        <ModalInputFields
          inputList={fileNameModalInputList}
          errors={errors}
          onChangeHandler={onChangeHandler}
        />
        <div className="flex justify-between">
          <button
            onClick={handleClose}
            disabled={isSaving}
            className="text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-gray-400">
            Cancel
          </button>
          <button
            onClick={handleConvert}
            disabled={isSaving}
            className="text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-secondary_bg_color relative overflow-hidden"
            style={{ minWidth: '100px' }}>
            {isSaving && (
              <div className="absolute inset-0 flex justify-center items-center">
                <ReactLoading type={'spin'} color="white" height={20} width={25} />
              </div>
            )}
            <span
              className={isSaving ? 'opacity-0' : 'opacity-100 transition-opacity duration-300'}>
              Convert
            </span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConvertToAutoMappingModal;
