import { AxiosResponse } from 'axios';
import axios from '../../../helpers/AxiosConfig';
import {
  DeleteCatalogsResponse,
  GetCatalogsParams,
  GetCatalogsResponse,
  GetSourceFilterOptionsResponse,
  GetSourceFilesResponse,
  AddCatalogParams,
  AddCatalogResponse,
  GetCatalogFileDataParams,
  GetCatalogFileDataResponse,
  GetCatalogColumnParams,
  GetCatalogColumnResponse,
} from './catalog.types';

export const getCatalogs = async (
  params: GetCatalogsParams,
): Promise<AxiosResponse<GetCatalogsResponse>> => {
  return await axios.get<GetCatalogsResponse>('catalogs', {
    params,
  });
};

export const getCatalogFilterOptions = async () => {
  return await axios.get<GetSourceFilterOptionsResponse>('catalogs/filter-options');
};

export const archiveCatalog = async (ids: number[]) => {
  return axios.delete<DeleteCatalogsResponse>(`/catalogs/delete`, {
    params: { ids },
  });
};

export const fetchSourceFiles = async (ids: number[], getOriginal = false) => {
  return axios.get<GetSourceFilesResponse>(`/catalog/getSourceFiles`, {
    params: { ids: JSON.stringify(ids), getOriginal: getOriginal },
  });
};

export const addCatalog = async (
  params: AddCatalogParams,
): Promise<AxiosResponse<AddCatalogResponse>> => {
  return await axios.post<AddCatalogResponse>('catalog/addToCatalog', params, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getCatalogData = async (
  params: GetCatalogFileDataParams,
): Promise<AxiosResponse<GetCatalogFileDataResponse>> => {
  return await axios.get<GetCatalogFileDataResponse>('/catalog/getCatalogFileData', {
    params,
  });
};

export const getCatalogColumn = async (
  params: GetCatalogColumnParams,
): Promise<AxiosResponse<GetCatalogColumnResponse>> => {
  return await axios.get<GetCatalogColumnResponse>('/catalogs/searchField', {
    params,
  });
};
