import { forwardRef, useImperativeHandle, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Input } from '@gloabal-regulatory-writing-consulting/gxt-components';
import InputError from '../../../../components/inputError/InputError';
import { CatalogTemplateFormProps } from './EditCatalogTemplate.types';
import { templateSchema } from '../../../CatalogTemplateForm/utils/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { getInputList } from './utils/InputFieldList';
import { CatalogTemplateFormValues } from './utils/constants';

interface EditCatalogTemplateFormProps {
  handleSubmitData: (data: CatalogTemplateFormProps) => void;
  defaultValues?: CatalogTemplateFormProps;
  templateType: string;
}

const EditCatalogTemplateForm = forwardRef<unknown, EditCatalogTemplateFormProps>(
  ({ handleSubmitData, defaultValues = CatalogTemplateFormValues, templateType }, ref) => {
    const {
      control,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm<CatalogTemplateFormProps>({
      resolver: zodResolver(templateSchema),
      mode: 'onChange',
      defaultValues,
    });

    const onSubmit: SubmitHandler<CatalogTemplateFormProps> = (data) => {
      handleSubmitData(data);
    };

    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(onSubmit),
    }));

    useEffect(() => {
      for (const key in defaultValues) {
        setValue(
          key as keyof CatalogTemplateFormProps,
          defaultValues[key as keyof CatalogTemplateFormProps],
        );
      }
    }, [defaultValues, setValue]);

    const inputList = getInputList(templateType);

    return (
      <form className="flex flex-col gap-4 self-stretch">
        {inputList.map((input, index) => (
          <Controller
            key={index}
            name={input.name as keyof CatalogTemplateFormProps}
            control={control}
            render={({ field }) => (
              <div className="w-full">
                <Input
                  {...field}
                  label={input.label}
                  type={input.type}
                  disabled={input.disabled}
                  className="w-full"
                  step={0.1}
                  customStyles={{ input: { height: '2.625rem' } }}
                  error={!!errors[input.name as keyof CatalogTemplateFormProps]?.message}
                  helpText={
                    <InputError
                      errors={errors}
                      field={input.name as keyof CatalogTemplateFormProps}
                    />
                  }
                />
              </div>
            )}
          />
        ))}
      </form>
    );
  },
);

EditCatalogTemplateForm.displayName = 'EditCatalogTemplateForm';

export default EditCatalogTemplateForm;
