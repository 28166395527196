import LastSaved from '../LastSaved';
import { Badge } from '@gloabal-regulatory-writing-consulting/gxt-components';

type InfoPanelProps = {
  lastSaved?: Date;
  badges?: {
    label: string;
    values: string[];
  }[];
};

const InfoPanel = ({ lastSaved, badges = [] }: InfoPanelProps) => {
  return (
    <div className="mb-5 flex justify-start items-center space-x-6">
      {lastSaved && <LastSaved timestamp={lastSaved} />}
      {badges.reduce((acc, item) => {
        if (item.values.length) {
          acc.push(
            <div className="flex gap-2 items-center">
              <h5 className="text-neutral-400">{item.label}</h5>
              {item.values.map((value) => (
                <Badge key={value} label={value} type="nosave" />
              ))}
            </div>,
          );
        }
        return acc;
      }, [] as React.ReactNode[])}
    </div>
  );
};

export default InfoPanel;
