import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Modal } from '../../components/Modal';
import { isPasswordValid, notifyError, notifySuccess } from '../../helpers/utils';
import { resetPassword, sendResetPasswordCode } from '../../helpers/amplify';
import { buttonStyle } from '../../constants';
import { useDynamicStylingContext } from '../../contexts/DynamicStylingContext';

export const ResetPasswordModal: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const handleCloseModal = () => {
    setShowModal(false);
    setShowResetPasswordForm(false);
  };

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className="mt-5
        btn btn-link
        text-decoration-none
        text-primary_color
        text-sm
        font-medium
      ">
        Forgot Password?
      </button>
      <Modal
        modalIsOpen={showModal}
        closeModal={handleCloseModal}
        title={showResetPasswordForm ? 'Request New Password' : 'Reset Password'}>
        {showResetPasswordForm ? (
          <ResetPasswordForm
            email={userEmail}
            setShowModal={setShowModal}
            setShowResetPasswordForm={setShowResetPasswordForm}
          />
        ) : (
          <RequestResetCodeForm
            userEmail={userEmail}
            setUserEmail={setUserEmail}
            setShowResetPasswordForm={setShowResetPasswordForm}
          />
        )}
      </Modal>
    </>
  );
};

function RequestResetCodeForm({
  setShowResetPasswordForm,
  userEmail,
  setUserEmail,
}: {
  setShowResetPasswordForm: Dispatch<SetStateAction<boolean>>;
  userEmail: string;
  setUserEmail: Dispatch<SetStateAction<string>>;
}) {
  const { clientName } = useDynamicStylingContext();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await sendResetPasswordCode(userEmail, clientName);
      notifySuccess('A password reset code is coming your way!');
      setShowResetPasswordForm(true);
    } catch (err: any) {
      console.error(err);
      notifyError(err.message);
    }
  };
  return (
    <div className="p-6">
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="userEmail" className="block font-medium mb-1">
            User Email
          </label>
          <input
            type="text"
            name="userEmail"
            value={userEmail}
            id="userEmail"
            onChange={(e) => setUserEmail(e.target.value)}
            placeholder="Enter Your Email"
            className="block w-full border rounded-md px-4 py-2 focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div className="flex justify-end">
          <input
            disabled={userEmail === ''}
            type="submit"
            className={`w-full text-white font-medium rounded-lg text-sm px-5 py-2.5 mt-12 text-center  ${buttonStyle(
              userEmail === '',
            )}`}
            value="Submit"
            data-testid="submit-button"
          />
        </div>
      </form>
    </div>
  );
}

function ResetPasswordForm({
  email,
  setShowModal,
  setShowResetPasswordForm,
}: {
  email: string;
  setShowResetPasswordForm: Dispatch<SetStateAction<boolean>>;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [verifyCode, setVerifyCode] = useState('');

  const handlePasswordSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!verifyCode) {
      notifyError('Please enter a valid reset code.');
      return;
    }
    if (!email) {
      notifyError('Please enter a valid user name.');
      return;
    }
    if (newPassword !== confirmPassword) {
      notifyError('Password and Confirm Password must match');
      return;
    }
    if (!isPasswordValid(newPassword)) {
      notifyError(
        'Password must be minimum 8 characters with at least one uppercase letter, one lowercase letter, one number and one special character.',
      );
      return;
    }
    const res = await resetPassword(email, verifyCode, newPassword);
    if (res.success) {
      notifySuccess('Password successfully updated!');
      setShowResetPasswordForm(false);
      setShowModal(false);
    } else {
      notifyError(res.message);
      console.error(res);
    }
  };

  return (
    <div className="p-6">
      <form className="space-y-4" onSubmit={handlePasswordSubmit}>
        <div>
          <label htmlFor="resetCode" className="block font-medium mb-1">
            Reset Code
          </label>
          <input
            type="text"
            name="resetCode"
            onChange={(e) => setVerifyCode(e.target.value)}
            value={verifyCode}
            id="resetCode"
            placeholder="Enter Reset Code"
            className="block w-full border rounded-md px-4 py-2 focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label htmlFor="newPassword" className="block font-medium mb-1">
            New Password
          </label>
          <input
            type="password"
            name="newPassword"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter New Password"
            className="block w-full border rounded-md px-4 py-2 focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label htmlFor="confirmPassword" className="block font-medium mb-1">
            Confirm Password
          </label>
          <input
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            id="confirmPassword"
            placeholder="Confirm Password"
            className="block w-full border rounded-md px-4 py-2 focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div className="flex justify-end">
          <input
            type="submit"
            className={`w-full text-white font-medium rounded-lg text-sm px-5 py-2.5 mt-12 text-center ${buttonStyle(
              false,
            )}`}
            value="Submit"
            data-testid="submit-button"
          />
        </div>
      </form>
    </div>
  );
}
