import { FC, useRef } from 'react';
import { Button, SlideOver } from '@gloabal-regulatory-writing-consulting/gxt-components';
import EditCatalogTemplateForm from './EditCatalogTemplateForm';
import { CatalogTemplateFormProps, EditCatalogTemplateProps } from './EditCatalogTemplate.types';
import { useMappingSessionAPI } from '../../../../services/api';
import { UpdateMappingSessionParams } from '../../../../services/api/types';

const EditCatalogTemplates: FC<EditCatalogTemplateProps> = ({
  isOpen,
  onClose,
  templateType,
  defaultValues,
}) => {
  const formRef = useRef<{ submitForm: () => void }>(null);
  const { editMappingSession } = useMappingSessionAPI();

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const handleSubmitData = async (data: CatalogTemplateFormProps) => {
    const catalogData = {
      id: defaultValues.id,
      title: data.templateName || '',
      version: data.version ? +data.version : '1.0',
      ctdSection: data.section || '',
    } as UpdateMappingSessionParams;

    await editMappingSession.mutateAsync(catalogData);
    onClose();
  };

  const heading = {
    Automated: 'Edit Automated Template Properties',
    Target: 'Edit Target Template Properties',
    Source: 'Edit Source Properties',
  };

  return (
    <SlideOver isOpen={isOpen} onClose={onClose} mountElementId="styled-wrapper" width="28rem">
      <SlideOver.Header className="flex p-6 flex-col items-start gap-2 self-stretch bg-primary-50 text-system-50">
        {heading[templateType]}
      </SlideOver.Header>
      <EditCatalogTemplateForm
        ref={formRef}
        handleSubmitData={handleSubmitData}
        templateType={templateType}
        defaultValues={defaultValues}
      />
      <SlideOver.Footer className="flex flex-col items-center self-stretch">
        <div className="flex gap-4 justify-end items-center flex-grow flex-shrink flex-basis-0 w-full">
          <Button
            variant="primary"
            onClick={handleFormSubmit}
            disabled={templateType !== 'Automated'}>
            Save
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </div>
      </SlideOver.Footer>
    </SlideOver>
  );
};

export default EditCatalogTemplates;
