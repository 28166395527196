/* eslint-disable react/display-name */
import { createColumnHelper } from '@tanstack/react-table';
import { DocumentTabs, ISourceDocument, MappingSessionRecord } from '../../../../../types';
import { getDate } from '../../../../../helpers/catalogHelpers';
import { TableHeader } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { CatalogSortParams, SortOrderType } from '../../../../../services/api/types';

const getCreatedByFullName = (createdBy: { firstName: string; lastName: string } | null) => {
  if (createdBy) {
    return `${createdBy.firstName} ${createdBy.lastName}`;
  }
  return '';
};

export const generateColumnsByTab = (
  activeTab: DocumentTabs,
  handleColumnSort: (sort: SortOrderType, column: keyof CatalogSortParams) => void,
  sortedColumn: { column: string; order: SortOrderType },
) => {
  const catalogColumnHelper = createColumnHelper<ISourceDocument>();
  const mappingSessionColumnHelper = createColumnHelper<MappingSessionRecord>();

  const createTableHeader = (
    title: string,
    accessor = '',
    activeColumn = { column: '', order: 'ASC' as SortOrderType },
  ) => {
    return () => (
      <TableHeader
        ColumnName={accessor}
        Title={title}
        handleColumnSort={handleColumnSort as (sort: SortOrderType, column: string) => void}
        activeColumn={activeColumn}
      />
    );
  };

  const columnsByTab: Record<DocumentTabs, any[]> = {
    [DocumentTabs.TARGET]: [
      catalogColumnHelper.accessor('id', {
        header: createTableHeader('ID', 'id', sortedColumn),
        cell: (info) => info.getValue(),
        size: 46,
      }),
      catalogColumnHelper.accessor('documentName', {
        header: createTableHeader('Template Name', 'documentName', sortedColumn),
        cell: (info) => info.getValue(),
        size: 450,
      }),
      catalogColumnHelper.accessor('section', {
        header: createTableHeader('Section', 'section', sortedColumn),
        cell: (info) => info.getValue(),
        size: 77,
      }),
      catalogColumnHelper.accessor('version', {
        header: createTableHeader('Version', 'version', sortedColumn),
        cell: (info) => info.getValue(),
        size: 89,
      }),
      catalogColumnHelper.accessor('project', {
        header: createTableHeader('Project'),
        cell: (info) => info.getValue(),
        size: 180,
      }),
      catalogColumnHelper.accessor('group', {
        header: createTableHeader('Group'),
        cell: (info) => info.getValue(),
        size: 180,
      }),
      catalogColumnHelper.accessor('uploadedBy', {
        header: createTableHeader('Uploaded By', 'uploadedBy', sortedColumn),
        cell: (info) => getCreatedByFullName(info.row.original.createdBy),
        size: 180,
      }),
    ],
    [DocumentTabs.SOURCE]: [
      catalogColumnHelper.accessor('id', {
        header: createTableHeader('ID', 'id', sortedColumn),
        cell: (info) => info.getValue(),
        size: 46,
      }),
      catalogColumnHelper.accessor('documentName', {
        header: createTableHeader('Document Name', 'documentName', sortedColumn),
        cell: (info) => info.getValue(),
        size: 450,
      }),
      catalogColumnHelper.accessor('version', {
        header: createTableHeader('Version', 'version', sortedColumn),
        cell: (info) => info.getValue(),
        size: 77,
      }),
      catalogColumnHelper.accessor('project', {
        header: createTableHeader('Project'),
        cell: (info) => info.getValue(),
        size: 139,
      }),
      catalogColumnHelper.accessor('group', {
        header: createTableHeader('Group'),
        cell: (info) => info.getValue(),
        size: 139,
      }),
      catalogColumnHelper.accessor('documentType', {
        header: createTableHeader('Source Type', 'documentType', sortedColumn),
        cell: (info) => info.getValue(),
        size: 113,
      }),
      catalogColumnHelper.accessor('site', {
        header: createTableHeader('Site', 'site', sortedColumn),
        cell: (info) => info.getValue(),
        size: 67,
      }),
      catalogColumnHelper.accessor('documentNum', {
        header: createTableHeader('Document #', 'documentNum', sortedColumn),
        cell: (info) => info.getValue(),
        size: 120,
      }),
      catalogColumnHelper.accessor('uploadedBy', {
        header: createTableHeader('Uploaded By', 'uploadedBy', sortedColumn),
        cell: (info) => getCreatedByFullName(info.row.original.createdBy),
        size: 139,
      }),
    ],
    [DocumentTabs.COMPLETED]: [
      catalogColumnHelper.accessor('id', {
        header: createTableHeader('ID', 'id', sortedColumn),
        cell: (info) => info.getValue(),
        size: 46,
      }),
      catalogColumnHelper.accessor('title', {
        header: createTableHeader('Template Name', 'title', sortedColumn),
        cell: (info) => info.getValue(),
        size: 450,
      }),
      catalogColumnHelper.accessor('section', {
        header: createTableHeader('Section'),
        cell: (info) => info.getValue(),
        size: 209,
      }),
      catalogColumnHelper.accessor('version', {
        header: createTableHeader('Version'),
        cell: (info) => info.getValue(),
        size: 209,
      }),
      catalogColumnHelper.accessor('project', {
        header: createTableHeader('Project'),
        cell: (info) => info.getValue(),
        size: 209,
      }),
      catalogColumnHelper.accessor('uploadedBy', {
        header: createTableHeader('Uploaded By', 'uploadedBy', sortedColumn),
        cell: (info) => getCreatedByFullName(info.row.original.createdBy),
        size: 209,
      }),
    ],
    [DocumentTabs.IN_PROGRESS]: [
      mappingSessionColumnHelper.accessor('id', {
        header: createTableHeader('ID', 'id', sortedColumn),
        cell: (info) => info.getValue(),
        size: 46,
      }),
      mappingSessionColumnHelper.accessor('title', {
        header: createTableHeader('Document Name', 'title', sortedColumn),
        cell: (info) => info.getValue(),
        size: 450,
      }),
      mappingSessionColumnHelper.accessor('project', {
        header: createTableHeader('Project'),
        cell: (info) => info.getValue(),
        size: 210,
      }),
      mappingSessionColumnHelper.accessor('mappingType', {
        header: createTableHeader('Type', 'mappingType', sortedColumn),
        cell: (info) => info.getValue(),
        size: 120,
      }),
      mappingSessionColumnHelper.accessor('ctdSection', {
        header: createTableHeader('Section', 'ctdSection', sortedColumn),
        cell: (info) => info.getValue(),
        size: 79,
      }),
      mappingSessionColumnHelper.accessor('status', {
        header: createTableHeader('Status', 'status', sortedColumn),
        cell: (info) => info.getValue(),
        size: 235,
      }),
      mappingSessionColumnHelper.accessor('createdAt', {
        header: createTableHeader('Created On', 'createdAt', sortedColumn),
        cell: (info) => {
          const createdAt = info.row.original.createdAt;
          return createdAt && getDate(createdAt);
        },
        size: 130,
      }),
      mappingSessionColumnHelper.accessor('createdBy', {
        header: createTableHeader('Owner', 'ownedBy', sortedColumn),
        cell: (info) => getCreatedByFullName(info.row.original.createdBy),
        size: 116,
      }),
    ],
    [DocumentTabs.AUTOMATED]: [
      catalogColumnHelper.accessor('id', {
        header: createTableHeader('ID', 'id', sortedColumn),
        cell: (info) => info.getValue(),
        size: 46,
      }),
      catalogColumnHelper.accessor('title', {
        header: createTableHeader('Template Name', 'title', sortedColumn),
        cell: (info) => info.getValue(),
        size: 450,
      }),
      catalogColumnHelper.accessor('ctdSection', {
        header: createTableHeader('Section', 'ctdSection', sortedColumn),
        cell: (info) => info.getValue(),
        size: 77,
      }),
      catalogColumnHelper.accessor('version', {
        header: createTableHeader('Version', 'version', sortedColumn),
        cell: (info) => info.getValue(),
        size: 89,
      }),
      catalogColumnHelper.accessor('project', {
        header: createTableHeader('Project'),
        cell: (info) => info.getValue(),
        size: 180,
      }),
      catalogColumnHelper.accessor('group', {
        header: createTableHeader('Group'),
        cell: (info) => info.getValue(),
        size: 180,
      }),
      catalogColumnHelper.accessor('uploadedBy', {
        header: createTableHeader('Uploaded By', 'uploadedBy', sortedColumn),
        cell: (info) => getCreatedByFullName(info.row.original.createdBy),
        size: 180,
      }),
    ],
    [DocumentTabs.PRE_AUTHORED]: [],
  };

  return columnsByTab[activeTab];
};
