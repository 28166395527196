import { Button, Search, SlideOver } from '@gloabal-regulatory-writing-consulting/gxt-components';
import Loading from 'react-loading';
import SvgIcon from '../../../../components/elements/SvgIcon';
import { CatalogFilesUploadStatus } from '../../../../types/CatalogTypes';

type UploadReportSlideOverProps = {
  isOpen: boolean;
  onClose: () => void;
  data: CatalogFilesUploadStatus[];
};

const icons: Record<CatalogFilesUploadStatus['status'], React.ReactNode> = {
  success: <SvgIcon iconType="check-mark" size="34" />,
  error: <SvgIcon iconType="warning" size="34" />,
  pending: <Loading color="bg-primary-200" type="spin" width={16} height={16} />,
};

const UploadReportSlideOver = ({ isOpen, onClose, data }: UploadReportSlideOverProps) => {
  return (
    <SlideOver isOpen={isOpen} onClose={onClose} width="24rem" mountElementId="styled-wrapper">
      <SlideOver.Header className="bg-primary-50 text-white">Upload Report</SlideOver.Header>
      <SlideOver.Footer className="text-white">
        <Button variant="secondary" onClick={onClose} className="ml-auto">
          Close
        </Button>
      </SlideOver.Footer>
      <div className="w-full">
        <Search onChangeCallback={() => {}} width="100%" />
      </div>
      <p>
        Documents with errors are required to be reviewed and uploaded again to appear on the
        catalog.
      </p>
      {data.map((file) => (
        <div key={file.id} className="flex items-center gap-3">
          {icons[file.status]}
          <p className="font-normal">{file.title}</p>
        </div>
      ))}
    </SlideOver>
  );
};

export default UploadReportSlideOver;
