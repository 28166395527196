import { useState, FC, Fragment, SyntheticEvent, ChangeEvent, useContext } from 'react';
import Input, { InputProps } from '../elements/Input';
import Avatar from '../avatar/Avatar';
import { notifySuccess } from '../../helpers/utils';
import { addUserToDb } from '../../services/apiCalls';
import UserRoles from './UserRoles';
import { USER_FORM_AVATAR_STYLING } from '../../constants/userForm';
import { useImageUploader } from '../../hooks/useImageUploader';
import { UserContext } from '../../contexts/UserContext';

interface newUserFields {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  avatar?: string;
}

interface ICreateUserForm {
  closeModal: () => void;
  getAllUsers: () => void;
}

const inputList: InputProps[] = [
  {
    label: 'First Name *',
    type: 'text',
    name: 'firstName',
    placeholder: 'Enter first name',
    className: 'block w-full mb-5',
  },
  {
    label: 'Last Name *',
    type: 'text',
    name: 'lastName',
    placeholder: 'Enter last name',
    className: 'block w-full  mb-5',
  },
  {
    label: 'Email *',
    type: 'email',
    name: 'email',
    placeholder: 'Enter email address',
    className: 'block w-full  mb-5',
  },
];

export const CreateUserForm: FC<ICreateUserForm> = ({ closeModal, getAllUsers }) => {
  const [error, setError] = useState<string>('');
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [newUserObj, setNewUserObj] = useState<newUserFields>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    avatar: '',
  });
  const [userPermissions, setUserPermissions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewUserObj((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateAvatar = (url: string) => {
    setNewUserObj((prevData) => ({
      ...prevData,
      avatar: url,
    }));
  };

  const createUser = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowLoader(true);
    if (!isFileValidated()) return;
    const { firstName, lastName, email, avatar } = newUserObj;

    // Note: This is a temporary solution to generate a unique username - ideally we should use the email as the username in the User Pool
    const userName = `${firstName}-${lastName}-${Date.now()}`.replace(/\s/g, '');

    try {
      const formObj = new FormData();
      formObj.append('userName', userName);
      formObj.append('firstName', firstName);
      formObj.append('lastName', lastName);
      formObj.append('email', email);
      avatar && formObj.append('avatar', avatar);
      formObj.append('roles', JSON.stringify(userPermissions.map((roles) => roles.value)));
      const resp = await addUserToDb(formObj);

      if (resp) {
        getAllUsers();
        closeModal();
        notifySuccess('User Created Successfully!');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const isFileValidated = () => {
    const requiredFields: (keyof newUserFields)[] = ['firstName', 'lastName', 'email'];
    const emptyField = requiredFields.find((field) => newUserObj[field] === '');

    if (emptyField || userPermissions.length < 1) {
      setError('Required fields cannot be empty');
      setShowLoader(false);
      return false;
    }

    setError('');
    return true;
  };

  const { userObj } = useContext(UserContext);
  const { firstName, lastName } = userObj;
  const { imageUrl, uploadImageToS3 } = useImageUploader();
  const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const imageFile = e.target.files?.[0];
    if (!imageFile) return;
    const userId = `${firstName[0]}${lastName}`.toLowerCase().replace(/\s/g, '');
    const fileName = `${userId}-avatar`;
    const response = await uploadImageToS3(imageFile, fileName);

    if (response && updateAvatar) {
      updateAvatar(response);
    }
  };

  return (
    <Fragment>
      <div className="flex justify-center relative m-auto mb-8">
        <Avatar
          handleImageUpload={handleImageUpload}
          user={newUserObj}
          avatarStyling={USER_FORM_AVATAR_STYLING}
          imageUrl={imageUrl}
        />
      </div>
      <form onSubmit={createUser}>
        <div className="h-70 mb-10">
          {inputList.map((item, index) => (
            <span key={index} className={item.className} title={item.tooltip}>
              <label
                htmlFor={item.name}
                className={`${item.labelClassName || 'block'} mb-2 text-sm font-medium`}>
                {item.label}
              </label>
              <Input
                {...(item.type === 'number' ? { min: '0' } : {})}
                type={item.type}
                name={item.name}
                value={newUserObj[item.name as keyof typeof newUserObj]}
                placeholder={item.placeholder}
                className={item.className}
                options={item.options}
                onChange={handleInputChange}
                dataTestId={`input-${item.name}`}
              />
            </span>
          ))}
          <label>Roles *</label>
          <UserRoles onChangeHandler={setUserPermissions} selectedRoles={userPermissions} />
          <p className="text-red-500 text-sm">{error}</p>
        </div>

        <div className="ml-32 mt-20 w-96 flex justify-between">
          <input
            type="button"
            value="Cancel"
            onClick={closeModal}
            title={'Edit Your Profile'}
            className="bg-slate-400 text-white font-medium rounded-lg text-sm px-5 py-2.5 w-40 text-center"
          />
          <input
            type="submit"
            value="Submit"
            className={`w-full text-white ${
              showLoader ? 'bg-gray-500' : 'bg-primary_bg_color'
            } font-medium rounded-lg text-sm px-5 py-2.5 w-40 text-center hover:${
              !showLoader && 'bg-secondary_bg_color'
            }`}
          />
        </div>
      </form>
    </Fragment>
  );
};
