import { MappingSessionSortParams } from './types';
import { CatalogFilterParams } from './types';
import { MappingSessionFilterParams } from './types';
import { CatalogSortParams } from './types';

export type SearchParams = {
  query: string;
  columns: string[];
};

export type PaginationParams = {
  page: number;
  perPage: number;
};

export type PaginationResponse<T> = {
  data: T[];
  total: number;
};

export type SortOrderType = keyof typeof sortOrder;

export type SortParams<S> = {
  type: SortOrderType;
  column: keyof S;
};

export type PaginationFilteredParams<F, S> = {
  filters?: {
    [key in keyof F]?: F[key] | F[key][];
  };
  search?: SearchParams;
  pagination?: PaginationParams;
  sort?: SortParams<S>;
};

export type Option = {
  label: string;
  value: string | number;
};

export type Filter = {
  label: string;
  name: string;
  options: Array<Option>;
};

export const sortOrder = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export type MessageResponse = {
  message: string;
};

export type InferSortParams<F> = F extends CatalogFilterParams
  ? CatalogSortParams
  : F extends MappingSessionFilterParams
    ? MappingSessionSortParams
    : never;

export * from './catalog/catalog.types';
export * from './mappingSession/mappingSession.types';
