import { FC, memo } from 'react';
import { DBUser } from '../../types';
import SvgIcon from '../elements/SvgIcon';
import TableSkeleton from '../TableSkeleton';
import { TableHeader } from '@gloabal-regulatory-writing-consulting/gxt-components';

//TODO: Change to react-table
const tableHeaders: Array<{ text: string; name: keyof DBUser }> = [
  { text: 'First Name', name: 'firstName' },
  { text: 'Last Name', name: 'lastName' },
  { text: 'Email', name: 'email' },
  { text: 'Actions', name: 'firstName' },
];

interface IUserTable {
  userList: DBUser[];
  handleShowDelete?: any;
  handleEdit?: (user: DBUser) => void;
  email: string;
  loading?: boolean;
  handleColumnSort?: (order: any, column: any) => void;
  isAdmin?: boolean;
}
const UserTable: FC<IUserTable> = ({
  userList,
  handleEdit,
  handleShowDelete,
  email,
  loading,
  handleColumnSort,
  isAdmin = false,
}) => {
  const renderHeaders = (
    <thead className="border border-primary_border_color text-xs uppercase bg-primary_bg_color text-white">
      <tr>
        {tableHeaders?.map((item, index) => (
          <th scope="col" className="py-4 px-6 sticky top-0 bg-primary_bg_color" key={index}>
            {handleColumnSort && item.text !== 'Actions' ? (
              <TableHeader
                handleColumnSort={handleColumnSort}
                Title={item.text}
                ColumnName={item.name}
              />
            ) : (
              item.text
            )}
          </th>
        ))}
      </tr>
    </thead>
  );

  const renderSkeleton = (
    <tr>
      <td colSpan={tableHeaders.length}>
        <TableSkeleton />
      </td>
    </tr>
  );

  const renderUsers = userList.length ? (
    userList?.map((item) => (
      <tr
        className="border bg-white border-primary_border_color hover:bg-table_row_bg_color"
        key={item.id}
        data-testid={`user-row`}>
        <td className="py-4 px-6 font-medium whitespace-nowrap">{item?.firstName}</td>
        <td className="py-4 px-6"> {item?.lastName}</td>
        <td className="py-4 px-6"> {item?.email}</td>

        <td className="py-4 px-6 space-x-2">
          {handleShowDelete && (
            <button
              className="px-2 py-1.5 rounded-md"
              onClick={() => handleShowDelete(item)}
              data-testid={`delete-user-${item?.firstName}-${item?.lastName}`}>
              <SvgIcon iconType={'delete'} />
            </button>
          )}
          {handleEdit && (item.email === email || isAdmin) && (
            <button
              data-testid={`edit-user-${item?.id}`}
              className={`px-2 py-1.5 rounded-md`}
              onClick={() => handleEdit(item)}>
              <SvgIcon iconType={'edit'} />
            </button>
          )}
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan={tableHeaders.length} className="text-center text-gray-600">
        No Record Found
      </td>
    </tr>
  );

  return (
    <div className="w-10/12 mx-auto text-sm text-center mt-5 overflow-hidden ">
      <div className="overflow-y-auto max-h-[600px]">
        <table className="w-full">
          {renderHeaders}
          <tbody>{loading ? renderSkeleton : renderUsers}</tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(UserTable);
