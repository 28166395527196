import { InputItem } from '../../types';
import Input from '../elements/Input';
import { ChangeEvent } from 'react';

interface ModalInputFieldsProps {
  inputList: InputItem[];
  onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  errors?: {
    [key: string]: string | boolean;
  };
  userAction?: boolean;
}

const ModalInputFields = ({ inputList, onChangeHandler, errors }: ModalInputFieldsProps) => {
  return (
    <>
      {inputList?.map((item, index) => (
        <span key={index} className={item?.className} title={item.tooltip}>
          <label
            htmlFor={item.name}
            className={`${item?.labelClassName || 'block'}  ${
              errors && errors[item.name] ? 'text-red-500' : 'text-gray-900'
            } mb-2 text-sm font-medium`}>
            {item.label}
          </label>
          <Input
            {...(item.type === 'number' ? { min: item.min || 0 } : {})}
            type={item.type}
            name={item.name}
            value={item.value}
            placeholder={item.placeholder}
            className={`${item.inputClassName}`}
            options={item.options}
            onChange={onChangeHandler}
            disabled={item.disabled}
            dataTestId={`input-${item.name}`}
          />
          {errors && errors[item.name] && (
            <p className="text-red-500 text-sm">{errors[item.name]}</p>
          )}
        </span>
      ))}
    </>
  );
};

export default ModalInputFields;
