// React related imports
import { FC } from 'react';
import { Controller } from 'react-hook-form';

// Components
import { Input } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { InputError } from '../../components/inputError';

// Types
import { ITemplateFields } from './types';

const TemplateFields: FC<ITemplateFields> = ({ control, errors }) => {
  return (
    <form className="flex flex-col items-start gap-4 w-2/5 self-stretch">
      <p className="text-lg font-bold leading-6 tracking-tighter text-primary-300">
        Template information
      </p>
      <div className="flex flex-col items-start gap-1 w-full">
        <Controller
          name="templateName"
          control={control}
          render={({ field }) => (
            <div className="w-full">
              <Input
                {...field}
                inputSize={'small'}
                label={'Template name*'}
                primary
                placeholder="Template name"
                customStyles={{
                  container: { width: '100%' },
                  input: { borderRadius: '0.375rem' },
                  label: { color: 'var(--Primary-300, #115873)' },
                }}
              />
              <InputError errors={errors} field={'templateName'} />
            </div>
          )}
        />
      </div>
      <div className="flex items-start gap-4 self-stretch">
        <Controller
          name="group"
          control={control}
          render={({ field }) => (
            <div className="w-full">
              <Input
                {...field}
                inputSize={'small'}
                label={'Group*'}
                primary
                placeholder="Select a Group"
                customStyles={{
                  container: { flex: 1 },
                  input: { borderRadius: '0.375rem' },
                  label: { color: 'var(--primary-300, #115873)' },
                }}
              />
              <InputError errors={errors} field={'group'} />
            </div>
          )}
        />
        <Controller
          name="section"
          control={control}
          render={({ field }) => (
            <div className="w-full">
              <Input
                {...field}
                inputSize={'small'}
                label={'Section*'}
                primary
                placeholder="Section"
                customStyles={{
                  container: { flex: 1 },
                  input: { borderRadius: '0.375rem' },
                  label: { color: 'var(--primary-300, #115873)' },
                }}
              />
              <InputError errors={errors} field={'section'} />
            </div>
          )}
        />
        {
          // TODO: This dropdown will be used for Section in future
          // <Dropdown
          //   customStyles={{
          //     button: { width: '100%', height: '36px' },
          //     container: { flex: 1 },
          //     placeholder: { justifyContent: 'flex-start' },
          //     label: { color: 'var(--Primary-300, #115873)', fontWeight: '700' },
          //   }}
          //   dropdownIcon
          //   placeholder="Section"
          //   label="Section*"
          //   onSelect={() => {}}
          //   options={['Option 1', 'Option 2', 'Option 3 is a very big option']}
          //   position="bottom"
          //   type="select"
          // />
        }
      </div>
      <div className="flex items-start gap-4 self-stretch">
        <Controller
          name="version"
          control={control}
          render={({ field }) => (
            <div className="w-1/2">
              <Input
                {...field}
                inputType={'number'}
                min={0}
                step={0.1}
                inputSize={'small'}
                label={'Version*'}
                defaultValue={'1.0'}
                primary
                placeholder="Add a version"
                customStyles={{
                  container: { marginRight: '0.5rem' },
                  input: { borderRadius: '0.375rem' },
                  label: { color: 'var(--primary-300, #115873)' },
                }}
              />
              <InputError errors={errors} field={'version'} />
            </div>
          )}
        />
      </div>
      <p className="text-primary-200 text-xs not-italic font-normal leading-[1.125rem] tracking-[0.00088rem]">
        Items marked with a * are required
      </p>
    </form>
  );
};

export default TemplateFields;
