// React-related imports
import { ChangeEvent, useCallback, useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// External libraries
import { useQuery } from '@tanstack/react-query';
import {
  Header,
  Tabs,
  SlideOverFilters,
} from '@gloabal-regulatory-writing-consulting/gxt-components';

// Hooks
import { usePaginatedFilters } from '../../hooks/usePaginatedFilters';
import useModal from '../../hooks/useModal';

// Components
import {
  CatalogActionBar,
  CatalogTable,
  CatalogUploadAlertMessage,
  UploadReportSlideOver,
} from './components';
import { Layout } from '../../components';
import { DeleteConfirmationDialogue } from '../../components/modals/DeleteConfirmationDialogue';

// API services
import { useCatalog } from '../../services/api';

// Utility functions
import { getFilterFetcher, mapToFilterOptions } from './util/helpers';
import defaultFilters from './util/defaultCatalogTabsFilters';

// Contexts
import { CatalogContext } from '../../contexts/CatalogContext';

// Types
import {
  CatalogFilterParams,
  CatalogSortParams,
  InferSortParams,
  MappingSessionFilterParams,
  MappingSessionSortParams,
} from '../../services/api/types';
import { DocumentTabs as TabsType } from '../../types';
import { ButtonConfig, Option } from './components/types';

const defaultTemplateType: Option = {
  title: 'Automated Templates',
  value: 'Automated',
};

const CatalogPage = () => {
  const { state } = useLocation();

  const [activeTab, setActiveTab] = useState(
    (state && (state.activeTab as TabsType)) || TabsType.TARGET,
  );
  const [templateType, setTemplateType] = useState(defaultTemplateType);
  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  const [appliedFilters, setAppliedFilters] = useState(
    defaultFilters(activeTab, templateType.value),
  );

  const {
    paginatedFilteredData,
    filters,
    setFilters,
    setSearchByKey,
    appliedFiltersCount,
    setAppliedFiltersCount,
    handlePageChange,
    handlePerPageChange,
    handleColumnSort,
  } = usePaginatedFilters<
    CatalogFilterParams | MappingSessionFilterParams,
    InferSortParams<CatalogSortParams & MappingSessionSortParams>
  >({
    filters: defaultFilters(activeTab, templateType.value),
    search: {
      query: '',
      columns: ['id', 'title'],
    },
    sort: { type: 'DESC', column: 'id' },
  });

  const sideOverFilters = useModal();
  const { deleteCatalog } = useCatalog();
  const isBulkDeleteDisabled = selectedRecords.length === 0;
  const slideOverReport = useModal();
  const navigate = useNavigate();
  const { catalogFilesUploadStatus } = useContext(CatalogContext);

  const showSourceDocuments = activeTab === TabsType.SOURCE;
  const showTargetDocuments = activeTab === TabsType.TARGET;

  // Tabs related to mapping sessions
  // const showInProgressMappingSessions = activeTab === TabsType.IN_PROGRESS; // In-Progress tab
  // const showCompletedMappingSessions = activeTab === TabsType.COMPLETED; // Completed tab

  const {
    show: showBulkDeleteModal,
    closeModal: closeBulkDeleteModal,
    openModal: openBulkDeleteModal,
  } = useModal();

  const fetchFilterOptions = async () => {
    try {
      const fetchFilterOptionsFn = getFilterFetcher(activeTab, templateType.value);

      const { data } = await fetchFilterOptionsFn();
      return data;
    } catch (error) {
      console.error('Error fetching filterOptions data:', error);
      throw error;
    }
  };

  const filtersQueryKey = useMemo(() => {
    if (
      (activeTab === TabsType.TARGET && templateType.value === 'Target') ||
      activeTab === TabsType.SOURCE
    )
      return 'CatalogFilterOptions';
    else return 'MappingSessionFilterOptions';
  }, [activeTab, templateType.value]);

  const filtersQuery = useQuery({
    queryFn: fetchFilterOptions,
    queryKey: [filtersQueryKey],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const tabs = useMemo(
    () => [
      {
        title: 'Templates',
        active: showTargetDocuments,
        onClickHandler: () => handleActiveTab(TabsType.TARGET),
      },
      {
        title: 'Sources',
        active: showSourceDocuments,
        onClickHandler: () => handleActiveTab(TabsType.SOURCE),
      },
      // {
      //   title: 'In-Progress', // TODO: Uncomment when needed
      //   active: showInProgressMappingSessions,
      //   onClickHandler: () => handleActiveTab(TabsType.IN_PROGRESS),
      // },
      // {
      //   title: 'Completed', // TODO: Uncomment when needed
      //   active: showCompletedMappingSessions,
      //   onClickHandler: () => handleActiveTab(TabsType.COMPLETED),
      // },
    ],
    [activeTab],
  );

  const handleActiveTab = (tab: TabsType) => {
    setFilters(defaultFilters(tab, templateType.value));
    setActiveTab(tab);
    handleColumnSort('DESC', 'id');
    if (tab === TabsType.TARGET) setTemplateType(defaultTemplateType);
  };

  const handleSelectAll = useCallback((allChecked: boolean, records: { id: string }[]) => {
    if (allChecked) {
      setSelectedRecords([]);
    } else {
      setSelectedRecords(records.map((item) => String(item.id)));
    }
  }, []);

  const handleSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedRecords((prev) => [...prev, event.target.value]);
    } else {
      setSelectedRecords((prev) => prev.filter((id) => id !== event.target.value));
    }
  };

  const handleBulkDelete = async () => {
    const records: number[] = selectedRecords.map((record) => Number(record));
    await deleteCatalog.mutateAsync(records).finally(() => {
      closeBulkDeleteModal();
    });

    setSelectedRecords([]);
  };

  const handleUpload = () => {
    switch (activeTab) {
      case TabsType.SOURCE:
        navigate('/upload/source');
        break;
      case TabsType.TARGET:
        navigate('/catalog/new');
        break;
    }
  };

  const actionButtons: ButtonConfig[] = useMemo(() => {
    const templateDropdown: ButtonConfig = {
      type: 'select',
      variant: 'secondary',
      options: [
        {
          title: 'Automated Templates',
          value: 'Automated',
        },
        {
          title: 'Manual Templates',
          value: 'Target',
        },
      ],
      optionValue: templateType,
      onSelect: (option) => {
        setFilters(defaultFilters(activeTab, option.value));
        setTemplateType(option);
      },
      title: 'Automated Templates',
      disabled: false,
      dataTestId: 'template-dropdown',
      styles: 'whitespace-nowrap',
      id: 'template-dropdown',
    };

    const commonButtons: ButtonConfig[] = [
      {
        type: 'button',
        variant: 'secondary',
        onClick: sideOverFilters.openModal,
        title: `Filter (${appliedFiltersCount})`,
        iconType: 'filter-icon',
        disabled: filtersQuery.isLoading,
        dataTestId: 'filter-btn',
        styles: 'flex justify-between items-center gap-3 whitespace-nowrap',
        id: 'filter-btn',
      },
    ];

    if (activeTab === TabsType.TARGET) {
      commonButtons.unshift(templateDropdown);
    }

    const bulkDeleteButton: ButtonConfig = {
      type: 'button',
      variant: 'negative',
      onClick: openBulkDeleteModal,
      title: 'Bulk Delete',
      disabled: isBulkDeleteDisabled,
      id: 'bulk-delete-btn',
      dataTestId: 'bulk-delete-btn',
      styles: 'whitespace-nowrap ',
    };

    if (activeTab === TabsType.IN_PROGRESS || activeTab === TabsType.COMPLETED) {
      return [...commonButtons, bulkDeleteButton];
    }

    return [
      ...commonButtons,
      {
        type: 'button',
        variant: 'secondary',
        onClick: handleUpload,
        title: 'Upload',
        disabled: ![TabsType.SOURCE, TabsType.TARGET].includes(activeTab),
        id: 'upload-btn',
        dataTestId: 'upload-btn',
        styles: 'whitespace-nowrap',
      },
      bulkDeleteButton,
    ];
  }, [activeTab, appliedFiltersCount, selectedRecords, openBulkDeleteModal]);

  const handleApplyFilters = () => {
    setFilters(appliedFilters);

    const appliedFiltersCount = Object.values(filters || {}).reduce((sum: number, value) => {
      if (Array.isArray(value) && value.length) {
        return sum + 1;
      }
      return sum;
    }, 0);

    setAppliedFiltersCount(appliedFiltersCount);
    sideOverFilters.closeModal();
  };

  const handleResetFilters = () => {
    setAppliedFilters(defaultFilters(activeTab, templateType.value));
  };

  const handleCloseFilters = () => {
    sideOverFilters.closeModal();
    setAppliedFilters(filters);
  };

  const filtersOptionsData = useMemo(() => {
    return mapToFilterOptions(filtersQuery.data, (filters) => {
      setAppliedFilters(filters as any);
    });
  }, [filtersQuery.data]);

  return (
    <Layout>
      <Layout.Header>
        <Header>
          <Header.Heading>Catalog</Header.Heading>
        </Header>
      </Layout.Header>
      <Layout.Body>
        <div className="flex items-center gap-3">
          <Tabs tabs={tabs} />
        </div>
        <CatalogUploadAlertMessage
          uploadFileStatus={catalogFilesUploadStatus}
          onReportClick={slideOverReport.openModal}
        />
        <CatalogActionBar
          templateType={templateType}
          activeTab={activeTab}
          handleSearch={(term) => {
            setSearchByKey('query', term);
          }}
          buttons={actionButtons}
        />
        <CatalogTable
          templateType={templateType.value}
          activeTab={activeTab}
          paginatedFilteredData={paginatedFilteredData}
          handleColumnSort={handleColumnSort}
          handleSelectAll={handleSelectAll}
          handleSelect={handleSelect}
          handlePageChange={handlePageChange}
          handlePerPageChange={handlePerPageChange}
          selectedRecords={selectedRecords}
        />
        <SlideOverFilters
          isOpen={sideOverFilters.show}
          onCloseHandler={handleCloseFilters}
          onApplyHandler={handleApplyFilters}
          onResetHandler={handleResetFilters}
          filtersOptions={filtersOptionsData}
          applyButtonLabel="Filter"
          closeButtonLabel="Close"
          resetButtonLabel="Clear Selection"
          title="Filters Templates"
        />
        <DeleteConfirmationDialogue
          isOpen={showBulkDeleteModal}
          handleClose={closeBulkDeleteModal}
          handleDelete={handleBulkDelete}
        />
        <UploadReportSlideOver
          data={catalogFilesUploadStatus}
          isOpen={slideOverReport.show}
          onClose={slideOverReport.closeModal}
        />
      </Layout.Body>
    </Layout>
  );
};

export default CatalogPage;
