import { FC } from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import TableSkeleton from './TableSkeleton';

interface ITable {
  data: any[];
  columns: any[];
  rowSelectionCallback?: (row: any) => void;
  isDataLoading?: boolean;
}

export const Table: FC<ITable> = ({
  data,
  columns,
  rowSelectionCallback,
  isDataLoading = false,
}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: rowSelectionCallback,
  });

  return (
    <table className="min-w-full">
      <thead className="rounded">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr
            key={headerGroup.id}
            style={{ top: '-1px' }}
            className="text-xs rounded-tl bg-primary_bg_color text-white sticky z-10">
            {headerGroup.headers.map((header, idx) => (
              <th
                key={header.id + idx}
                {...{
                  className: `${
                    (idx === 0 && 'rounded-tl') ||
                    (idx === headerGroup.headers.length - 1 && 'rounded-tr')
                  } text-xs bg-primary_bg_color p-2 text-white sticky top-0 max-h-6 text-[16px] font-bold tracking-[0.019px]`,
                  colSpan: header.colSpan,
                  style: {
                    width:
                      header.id !== 'id' && header.id !== 'Actions'
                        ? header.getSize() !== 120
                          ? header.getSize()
                          : 'undefined'
                        : '16px',
                  },
                }}>
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
                <div
                  {...{
                    onMouseDown: header.getResizeHandler(),
                    onTouchStart: header.getResizeHandler(),
                    className: `absolute top-0 right-0 h-full w-0.5 bg-black bg-opacity-50 cursor-col-resize select-none touch-action-none resizer ${
                      header.column.getIsResizing() ? 'isResizing' : ''
                    }`,
                    style: {
                      transform: header.column.getIsResizing()
                        ? `translateX(${table.getState().columnSizingInfo.deltaOffset}px)`
                        : '',
                    },
                  }}
                />
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="table-header-group">
        {isDataLoading ? (
          <TableSkeleton columns={columns} />
        ) : (
          table.getRowModel().rows.map((row) => (
            <tr
              key={row.original.id}
              className="odd:bg-[#F9FAFB] rounded-md p-1 py-2 w-full table-row">
              {row.getVisibleCells().map((cell, idx) => (
                <td
                  className="h-6 border-r"
                  key={cell.id + idx}
                  {...{
                    style: {
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '150%',
                      letterSpacing: '0.019px',
                      padding: '4px 8px',
                      height: '24px',
                      width:
                        cell.column.id !== 'id' && cell.column.id !== 'Actions'
                          ? cell.column.getSize()
                          : '16px',
                      maxWidth: '400px',
                      textAlign:
                        cell.column.id === 'documentName' ||
                        cell.column.id === 'documentTitle' ||
                        cell.column.id === 'title'
                          ? 'left'
                          : 'center',
                    },
                  }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};
