// React-related imports
import { FC, useMemo, useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { PaginatedTable } from '../../../../components/PaginatedTable';
import TinyMceModal from '../Modal/TinyMceModal/TinyMceModal';
import { DeleteConfirmationDialogue } from '../../../../components/modals/DeleteConfirmationDialogue';
import { EditCatalogTemplates } from '../EditCatalogTemplates';

// API services
import { getCatalogs, getMappingSessions, useCatalog } from '../../../../services/api';

// Types
import { CatalogTableProps } from './CatalogTable.types';
import { DocumentTabs } from '../../../../types';
import { MappingSession } from '../../../../services/api/types';

// Utility functions
import { generateColumnsByTab } from './utils/catalogColumns';
import { AddSourcesPreviousAction } from '../../util/constants';

// Other utility components
import generateMenuItems from './utils/menuItems';

// Hooks
import useModal from '../../../../hooks/useModal';

const CatalogTable: FC<CatalogTableProps> = ({
  activeTab,
  paginatedFilteredData,
  handleColumnSort,
  templateType,
  handleSelectAll = () => {},
  handleSelect = () => {},
  handlePageChange = (_page: number) => {},
  handlePerPageChange = (_perPage: number) => {},
  selectedRecords,
}) => {
  const [selectedId, setSelectedId] = useState<number>(0);
  const [isDocInteractive, setDocInteractive] = useState(true);
  const [defaultFormValues, setDefaultFormValues] = useState({
    templateName: '',
    version: '1.0',
    id: 0,
    section: '',
  });

  const navigate = useNavigate();

  const catalogEdit = useModal();

  const { deleteCatalog } = useCatalog();

  const sortedColumn = useMemo(
    () => ({
      column: paginatedFilteredData.sort?.column || '',
      order: paginatedFilteredData.sort?.type || 'DESC',
    }),
    [paginatedFilteredData.sort?.column, paginatedFilteredData.sort?.type],
  );

  const activeTabColumns = generateColumnsByTab(
    activeTab === DocumentTabs.TARGET && templateType === 'Automated'
      ? DocumentTabs.AUTOMATED
      : activeTab,
    handleColumnSort,
    sortedColumn,
  );

  const {
    show: showPreviewModal,
    closeModal: closePreviewModal,
    openModal: openPreviewModal,
  } = useModal();

  const {
    show: showDeleteModal,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal();

  const handleDelete = async () => {
    await deleteCatalog.mutateAsync([selectedId]);
    closeDeleteModal();
  };

  const setPreviewDocId = useCallback((id: number) => {
    setSelectedId(id);
  }, []);

  const handlePreviewDocument = useCallback(
    (id: number, documentType: boolean) => {
      setPreviewDocId(id);
      openPreviewModal();
      setDocInteractive(documentType);
    },
    [setPreviewDocId, openPreviewModal],
  );

  const getFilteredPaginatedCatalogs = () => {
    return getCatalogs(paginatedFilteredData);
  };

  const getFilteredPaginatedMappingSessions = () => {
    return getMappingSessions<MappingSession>(paginatedFilteredData);
  };

  const templateAPI = {
    Target: getFilteredPaginatedCatalogs,
    Automated: getFilteredPaginatedMappingSessions,
  };

  const apiCalls: Partial<Record<DocumentTabs, any>> = {
    [DocumentTabs.TARGET]: templateAPI[templateType],
    [DocumentTabs.SOURCE]: getFilteredPaginatedCatalogs,
    [DocumentTabs.IN_PROGRESS]: getFilteredPaginatedMappingSessions,
    [DocumentTabs.COMPLETED]: getFilteredPaginatedMappingSessions,
    [DocumentTabs.AUTOMATED]: getFilteredPaginatedMappingSessions,
  };

  const handleEditCatalog = (row: {
    original: { title: string; version: string; id: number; ctdSection: string };
  }) => {
    setDefaultFormValues({
      templateName: row.original.title || '',
      version: row.original.version || '1.0',
      id: row.original.id,
      section: row.original.ctdSection || '',
    });
    catalogEdit.openModal();
  };

  const startAuthoringOnClick = (id: string) => {
    navigate('/add-source', {
      state: {
        sessionId: id,
        templateType,
        previousAction: AddSourcesPreviousAction.AUTHORING_REQUEST,
      },
    });
  };

  const menuItems = useMemo(
    () =>
      generateMenuItems(
        activeTab,
        openDeleteModal,
        setSelectedId,
        handlePreviewDocument,
        handleEditCatalog,
        startAuthoringOnClick,
      ),
    [activeTab, handlePreviewDocument, openDeleteModal, templateType],
  );

  useEffect(() => {
    // TODO - Fix Params
    // updateParams({ basePath: 'catalog-list', filters: paginatedFilteredData.filters, activeTab });
  }, [activeTab, paginatedFilteredData.filters]);

  return (
    <>
      <PaginatedTable
        additionalColumns={activeTabColumns}
        paginatedFilteredData={paginatedFilteredData}
        getData={apiCalls[activeTab]}
        menuItems={menuItems}
        handleSelectAll={handleSelectAll}
        onSelect={handleSelect}
        selectedData={selectedRecords}
        handlePageChange={handlePageChange}
        handlePerPageChange={handlePerPageChange}
        queryKey={['Catalog', activeTab, templateType]}
        actionsColumn
      />
      <DeleteConfirmationDialogue
        isOpen={showDeleteModal}
        handleClose={closeDeleteModal}
        handleDelete={handleDelete}
      />
      <TinyMceModal
        closeEditorModalOpen={closePreviewModal}
        editorModalOpen={showPreviewModal}
        key="catalog_preview"
        editorType="Source"
        catalogId={selectedId}
        isFileInteractive={isDocInteractive}
      />
      {catalogEdit.show && (
        <EditCatalogTemplates
          isOpen={catalogEdit.show}
          onClose={catalogEdit.closeModal}
          templateType={activeTab === DocumentTabs.TARGET ? templateType : DocumentTabs.SOURCE}
          defaultValues={defaultFormValues}
        />
      )}
    </>
  );
};

export default CatalogTable;
