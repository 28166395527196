import { ModalHeaderProps } from '../../types';
import { FiX } from 'react-icons/fi';

const ModalHeader = ({ title, closeModal, parentBoxStyle = '' }: ModalHeaderProps) => {
  return (
    <div className={`flex justify-between items-center ${parentBoxStyle}`}>
      <h1 className="text-xl font-bold leading-tight tracking-tight text-black capitalize">
        {title}
      </h1>
      <FiX
        size={25}
        onClick={closeModal}
        className="bg-primary_bg_color text-white p-1 ml-4 rounded-full hover:bg-secondary_bg_color transition-colors duration-300 cursor-pointer"
      />
    </div>
  );
};

export default ModalHeader;
