/* eslint-disable react-hooks/exhaustive-deps */
import { toast } from 'react-toastify';
import { archiveMappingSession, getMappingSessions } from '../services/apiCalls';

import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import '../assets/styles/dropdown.css';
import { useNavigate } from 'react-router-dom';
import { useFilterStates } from '../hooks/useFilterStates';
import { generalizedDocumentsTableHeaders } from '../constants';
import { Search } from './Search';
import GeneralizedActions from './GeneralizedActions';
import BreadCrumbs from './elements/BreadCrumbs';
import { Tab } from './Tab';
import EditGeneralizationDoc from './elements/EditGeneralizationModal';
import { Table } from './table/Table';
import { useInfiniteScroll } from '../hooks/useInfiniteScrolling';
import Dropdown from './elements/dropdown';
import {
  GeneralizationFilterType,
  GeneratedDocumentColumns,
  GeneratedDocumentTabs,
  MappingSessionRecord,
} from '../types';
import { updateParams } from '../helpers/paramsHelpers';
import Loading from './Loading';
import ConvertToAutoMappingModal from './elements/ConvertToAutoMappingModal';
import useModal from '../hooks/useModal';
import { TableHeader } from '@gloabal-regulatory-writing-consulting/gxt-components';
const RECORDS_PER_PAGE = 15;

const GeneralizedDocuments = () => {
  const [selectedDoc, setSelectedDoc] = useState<MappingSessionRecord | null>(null);
  const { show: showEditModal, setShow: setShowEditModal } = useModal();
  const { show: showAutoMappingModal, setShow: setShowAutoMappingModal } = useModal();
  const generatedDocumentRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { filters, setFilters } = useFilterStates<GeneralizationFilterType>({
    sortType: '',
    columnSort: '',
    createdById: '',
    text: '',
    activeTab: 'auto',
  });
  const { activeTab, createdById, columnSort, sortType } = filters;

  const { data, refreshData, isDataLoading, response } = useInfiniteScroll(
    { type: activeTab, statusType: 'pending' },
    generatedDocumentRef,
    getMappingSessions,
    RECORDS_PER_PAGE,
    {
      ...filters,
    },
  );

  const uploadedByFilterOptions = useMemo(() => {
    if (response) {
      return response.distinctCreators?.map((item: { id: number; name: string }) => ({
        value: item.id,
        key: item.name,
        text: item.name,
      }));
    }
  }, [response]);

  const navigate = useNavigate();

  const handleGeneralizationDelete = (id: number) => {
    archiveMappingSession(id)
      .then((res) => {
        toast.success(res.data.message);
        refreshData();
      })
      .catch((res) => {
        toast.error(res.data.message);
      });
  };

  const handleShowEditModal = () => {
    setShowEditModal(true);
  };

  const handleEditDialog = (item: MappingSessionRecord) => {
    setSelectedDoc(item);
    handleShowEditModal();
  };

  const handleAutoMappingDialog = (item: MappingSessionRecord) => {
    setShowAutoMappingModal(true);
    setSelectedDoc(item);
  };

  const createdByChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilters('createdById', e.target.value);
  };

  const handleQueryChange = (searchTerm: string) => setFilters('text', searchTerm);

  const handleTabs = (type: GeneratedDocumentTabs) => setFilters('activeTab', type);

  const handleColumnSort = (sort: string, column: string) => {
    setFilters('columnSort', column);
    setFilters('sortType', sort);
  };

  useEffect(() => {
    updateParams({ basePath: 'mapping-sessions', filters, activeTab, type: activeTab });
  }, [filters, activeTab]);

  const getValue = (column: any, row: any, info: any) => {
    switch (column.id) {
      case 'createdBy':
        return info?.getValue()?.firstName + ' ' + info.getValue()?.lastName;
      case 'createdAt': {
        const originalDate = info.getValue();
        const dateObject = new Date(originalDate);
        const formattedDate = dateObject.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
        return formattedDate;
      }
      case 'catalogIds':
        return info.cell.row.original.sourceFileIds?.join(', ');
      case 'totalSuggestionsCount': {
        const autowriteStatus = info.cell.row.original.autowriteStatus;

        if (autowriteStatus === 'pending') return 'Pending';
        else if (autowriteStatus === 'complete')
          return info.cell.row.original.totalSuggestionsCount;
        else if (autowriteStatus === 'failed') return 'Failed';
        break;
      }
      case 'status': {
        return info.cell.row.original.selectedSuggestionsCount === 0 ? 'Pending' : 'In Progress';
      }
      case 'autowriteStatus': {
        const autowriteStatus = info.cell.row.original?.autowriteStatus;
        return autowriteStatus && autowriteStatus[0].toUpperCase() + autowriteStatus.slice(1);
      }
      case 'Actions':
        return (
          <GeneralizedActions
            handleEditDialog={() => handleEditDialog(row.original)}
            isDeleteActionVisible={
              row.original?.status && row.original?.status !== 'Waiting for AI Response'
            }
            handleVerify={() => navigate(`/mapping-sessions/${row.original.id}/dv`)}
            isVerifyActionVisible={true}
            handleAuthor={() => navigate(`/mapping-sessions/${row.original.id}`)}
            isAuthorVisible={activeTab !== 'pre-authored'}
            handleDeleteItem={() => handleGeneralizationDelete(row.original.id)}
            handleConvertToAutomapping={() => handleAutoMappingDialog(row.original)}
            id={row.original.id}
            type={activeTab}
          />
        );
      default:
        return info.getValue();
    }
  };

  const columns = useMemo(() => {
    return Object.keys(generalizedDocumentsTableHeaders).reduce((acc, key) => {
      if (activeTab !== 'auto' && key === 'autowriteStatus') return acc;
      const item = generalizedDocumentsTableHeaders[key];
      const column = {
        accessorKey: key,
        header: () =>
          ['Actions', 'catalogIds', 'createdBy', 'status'].includes(key) ? (
            item[0]
          ) : (
            <TableHeader ColumnName={item[1]} Title={item[0]} handleColumnSort={handleColumnSort} />
          ),
        cell: (info: any) => {
          return getValue(info.column, info.row, info);
        },
      };
      return column ? [...acc, column] : acc;
    }, [] as GeneratedDocumentColumns[]);
  }, [activeTab]);

  useEffect(() => {
    setIsLoading(true);
    refreshData();
  }, [activeTab, columnSort, sortType]);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [data]);

  return (
    <>
      <BreadCrumbs
        className="w-10/12 ml-4 text-sm text-left mt-8 mb-8 identifiers"
        data={[
          { link: '/', text: 'Home' },
          { text: 'Generated Documents', clickable: false },
        ]}
      />
      <ConvertToAutoMappingModal
        show={showAutoMappingModal}
        handleClose={() => setShowAutoMappingModal(false)}
        mappingSessionData={selectedDoc}
      />
      <EditGeneralizationDoc
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        automatedDocData={selectedDoc}
        refreshData={refreshData}
      />
      <h1 className="font-bold text-center my-4">Generated Documents</h1>
      <div className="flex w-10/12 ml-4 text-sm text-left mt-1 items-end">
        <div className="items-end flex space-x-1">
          <Tab
            active={activeTab === 'auto'}
            title="Auto Generated"
            onClickHandler={() => handleTabs('auto')}
          />
          <Tab
            active={activeTab === 'manual'}
            title="Manually Generated"
            onClickHandler={() => handleTabs('manual')}
          />
          <Tab
            active={activeTab === 'pre-authored'}
            title="Pre-Authored Documents"
            onClickHandler={() => handleTabs('pre-authored')}
          />
        </div>
        <div className="flex right-4 -mt-10 items-end space-x-2 absolute">
          <span className="">
            <Search id="gsearch" name="gsearch" onChangeCallback={handleQueryChange} />
          </span>
          <div className="mb-0 flex justify-end space-x-2">
            <Dropdown
              options={uploadedByFilterOptions}
              placeholder="Mapped By"
              changeHandler={createdByChangeHandler}
              selectedValue={createdById}
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loading backgroundColor="bg-zinc-200" type="spin" />
      ) : (
        <div className="my-4 mx-4 mt-0">
          <div className="overflow-y-auto h-[800px]" ref={generatedDocumentRef}>
            <Table data={data} columns={columns} isDataLoading={isDataLoading} />
          </div>
        </div>
      )}
      {!data?.length && !isLoading && (
        <p data-testid="no-data-found" className="mt-4 text-center text-gray-600">
          No Data Found
        </p>
      )}
    </>
  );
};

export default GeneralizedDocuments;
