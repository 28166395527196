import { useState } from 'react';
import { GeneratedDocumentTabs } from '../types';
import Dropdown, { DropdownListType } from './elements/headless/Dropdown';
import { DeleteConfirmationDialogue } from './modals/DeleteConfirmationDialogue';

type GeneralizedActionsPropType = {
  isDeleteActionVisible: boolean;
  isVerifyActionVisible: boolean;
  isAuthorVisible: boolean;
  handleDeleteItem: () => void;
  handleEditDialog?: (id: number) => void;
  handleVerify?: (id: number) => void;
  handleAuthor?: (id: number) => void;
  handleConvertToAutomapping?: (id: number) => void;
  isEditActionVisible?: boolean;
  id: number;
  type: GeneratedDocumentTabs;
};

const GeneralizedActions = (props: GeneralizedActionsPropType) => {
  const {
    isDeleteActionVisible,
    isVerifyActionVisible,
    isAuthorVisible,
    handleDeleteItem,
    handleEditDialog,
    handleVerify,
    handleAuthor,
    handleConvertToAutomapping,
    isEditActionVisible = true,
    id,
    type,
  } = props;

  const [show, setShow] = useState(false);

  const deleteClickHandler = () => setShow(true);

  const actionList = () => {
    const items: DropdownListType = [];
    if (isAuthorVisible) {
      items.push({
        label: type === 'automapping' ? 'Validate' : 'Author',
        onClickHandler: handleAuthor,
      });
    }
    if (isVerifyActionVisible) {
      items.push({ label: 'Verify', onClickHandler: handleVerify });
    }
    if (isDeleteActionVisible) {
      items.push({
        label: 'Delete',
        onClickHandler: deleteClickHandler,
      });
    }
    if (isEditActionVisible) {
      items.push({ label: 'Edit', onClickHandler: handleEditDialog });
    }
    if (type === 'manual' || type === 'auto') {
      items.push({
        label: 'Convert to Automapping',
        onClickHandler: handleConvertToAutomapping,
      });
    }
    return items;
  };

  return (
    <>
      <DeleteConfirmationDialogue
        isOpen={show}
        handleClose={() => setShow(false)}
        item="this file"
        handleDelete={handleDeleteItem}
      />
      <Dropdown list={actionList()} id={id} />
    </>
  );
};

export default GeneralizedActions;
