// React-related imports
import { useState } from 'react';

// Helpers
import {
  columnCells,
  convertAIResponseToSuggestions,
  getCellElement,
  getElement,
  setTargetElement,
} from '../../helpers/generalizationHelpers';

// Hooks
import useModal from '../useModal';

// Types
import { AIResponse, UseQuickFillParams } from '../../types';

// Hooks
import useAsyncOperation from '../useAsyncOperation';

export const useQuickFill = ({
  currentTargetNodeId,
  getCurrentNodeId,
  updatedAiResponse,
  callSaveSuggestions,
  editor1Ref,
  sessionId,
  aiResponse,
  targetNodeIdSuggestionMap,
}: UseQuickFillParams) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const { show: isQuickFillModalOpen, closeModal, openModal: openQuickFillModal } = useModal();

  const { isLoading: isSavingFilledSuggestions, executeCallback: handleAcceptSuggestions } =
    useAsyncOperation(callSaveSuggestions);

  const handleOptionChange = (option: string) => {
    setSelectedOption((prevSelectedOption) => (prevSelectedOption === option ? null : option));
  };

  const clearSelection = () => {
    setSelectedOption(null);
  };

  const handleApplyFilter = async (filterValue: string) => {
    if (!currentTargetNodeId) return;

    const selectedElement = getElement(editor1Ref, currentTargetNodeId) as HTMLElement;
    const currentNode = getCellElement(selectedElement, editor1Ref?.current?.getBody() as Node);

    if (!currentNode) return;

    const nodeIndex = currentNode.cellIndex;
    const warpperElement = currentNode.parentElement?.parentElement;
    const originalCellsCount = (currentNode.parentElement as HTMLTableRowElement).cells.length;

    if (!warpperElement) return;

    const intermediateNode =
      warpperElement.nodeName === 'THEAD'
        ? warpperElement.nextElementSibling || warpperElement
        : warpperElement;

    const suggestionNodes = columnCells(
      intermediateNode as HTMLTableElement,
      nodeIndex,
      originalCellsCount,
    ).map((node) => getCurrentNodeId(node, editor1Ref, updatedAiResponse));

    if (!suggestionNodes.length || !aiResponse) return;

    const filteredAIResponse = suggestionNodes.reduce((accumulator: AIResponse, suggestionNode) => {
      const suggestions = aiResponse[suggestionNode];
      if (suggestions) {
        const filteredSuggestions = suggestions.filter((suggestion) =>
          Object.values(suggestion)[0].groupingVariables.includes(filterValue),
        );
        if (filteredSuggestions?.length) {
          const [_sourceNodeId, attributes] = Object.entries(aiResponse[suggestionNode][0])[0];
          setTargetElement(editor1Ref, attributes.value, suggestionNode);
          accumulator = {
            ...accumulator,
            [suggestionNode]: [aiResponse[suggestionNode][0]],
          };
        }
      }
      return accumulator;
    }, {} as AIResponse);
    const suggestions = convertAIResponseToSuggestions(
      filteredAIResponse,
      editor1Ref,
      sessionId,
      targetNodeIdSuggestionMap,
      true,
    );

    await handleAcceptSuggestions(suggestions);
  };

  const closeQuickFillModal = () => {
    setSelectedOption(null);
    closeModal();
  };

  return {
    selectedOption,
    handleOptionChange,
    clearSelection,
    handleApplyFilter,
    isQuickFillModalOpen,
    closeQuickFillModal,
    openQuickFillModal,
    isSavingFilledSuggestions,
  };
};
