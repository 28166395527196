import { useContext } from 'react';

import { Button, Header } from '@gloabal-regulatory-writing-consulting/gxt-components';

import { AuthoringHeaderProps } from './types';

import { GeneralizationContext } from '../../../../contexts/GeneralizationContext';
import { useMappingSessionAPI } from '../../../../services/api/mappingSession/useMappingSessionAPI';

const AuthoringHeader = ({
  isAutoMapping = false,
  isLoading,
  isSaving,
  handleSave,
  handleClose,
}: AuthoringHeaderProps) => {
  const { sessionId } = useContext(GeneralizationContext);

  const { updateMappingSessionStatus } = useMappingSessionAPI();
  const { mutateAsync: updateSessionStatus, isPending } = updateMappingSessionStatus;

  const statusUpdateHandler = (status: string) => {
    if (sessionId) {
      updateSessionStatus({ status, id: sessionId });
    } else {
      console.error('Unable to find session ID');
    }
  };

  return (
    <Header>
      <Header.Heading className="!text-primary-400">
        {isAutoMapping ? 'Mapping' : 'Authoring'}
      </Header.Heading>
      <Header.Actions>
        {!isAutoMapping && (
          <>
            <Button variant="secondary" onClick={() => handleSave()} disabled={isSaving}>
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </>
        )}
        <Button variant="negative" onClick={handleClose} disabled={isPending}>
          Close
        </Button>
        <div className="h-10 border width-2 border-neutral-200" />
        {!isAutoMapping ? (
          <>
            <Button
              variant="primary"
              onClick={() => statusUpdateHandler('complete')}
              disabled={isPending}>
              Ready to Review
            </Button>
            <Button
              variant="secondary"
              onClick={() => statusUpdateHandler('finished')}
              disabled={isPending}>
              Document Complete
            </Button>
          </>
        ) : (
          <Button variant="primary">Finish</Button>
        )}
      </Header.Actions>
    </Header>
  );
};

export default AuthoringHeader;
