import React from 'react';

interface TableSkeletonProps {
  numRows?: number;
  columns: { id: string; size: number }[];
}

const TableSkeleton: React.FC<TableSkeletonProps> = ({ numRows = 15, columns }) => {
  return (
    <>
      {[...Array(numRows)].map((_, rowIndex) => (
        <tr key={`skeleton-${rowIndex}`} className="animate-pulse">
          {columns.map((column, colIndex) => (
            <td
              key={`skeleton-cell-${colIndex}`}
              className={`h-6 border-r p-2 w-[${column?.size}px]`}>
              <div className="bg-gray-200 h-4 rounded w-full"></div>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default TableSkeleton;
