import { FC } from 'react';
import { Modal, Button } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { DeleteModalProps } from './DeleteConfirmationDialogue.types';

const DeleteConfirmationDialogue: FC<DeleteModalProps> = ({
  maxHeight,
  overlayBackground,
  overlay = true,
  isOpen,
  handleClose,
  handleDelete,
  item = '',
  description = '',
}) => {
  return (
    <Modal
      isOpen={isOpen}
      maxWidth="24rem"
      maxHeight={maxHeight}
      overlayBackground={overlayBackground}
      overlay={overlay}
      onClose={handleClose}>
      <Modal.ModalHeader>
        <Modal.ModalHeaderHeading>Delete?</Modal.ModalHeaderHeading>
        <Modal.ModalHeaderBody>
          Are you sure you want to delete{item ? ` ${item}?` : '?'}
        </Modal.ModalHeaderBody>
        {description && (
          <Modal.ModalHeaderBody>
            <div className="text-center flex justify-center text-[var(--negative-100,#EF4444)] font-bold self-center">
              {description}
            </div>
          </Modal.ModalHeaderBody>
        )}
      </Modal.ModalHeader>
      <Modal.ModalFooter>
        <Button variant="primary" onClick={handleDelete}>
          Confirm
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.ModalFooter>
    </Modal>
  );
};

export default DeleteConfirmationDialogue;
