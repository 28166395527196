import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { getBackgroundColorClass } from '../../../constants';
import { twMerge } from 'tailwind-merge';

export type DropdownListType = Array<{
  label: string;
  onClickHandler: any;
}>;

type DropdownProps = {
  list: DropdownListType;
  id: number;
  disabled?: boolean;
};

const Dropdown = ({ list, id, disabled = false }: DropdownProps) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          data-testid={`dropdown-${id}`}
          disabled={disabled}
          className={twMerge(
            'rounded-lg px-3 py-1 text-xl font-bold text-white',
            getBackgroundColorClass(!disabled),
          )}>
          <span>&#8942;</span>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {list.map(({ label, onClickHandler }) => (
              <Menu.Item key={label}>
                {(_item: { active: boolean }) => (
                  <button
                    className="block w-full whitespace-nowrap bg-transparent py-2 px-4 text-sm font-normal text-gray-700 hover:bg-gray-300"
                    data-testid={`${label.toLowerCase()}-${id}`}
                    onClick={onClickHandler}>
                    {label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
