import { MappingTypeEnum } from '../../../../types';
import { getGroupedOptions } from '../helpers';

export const actionOptions = {
  quickFill: {
    value: 'Quick Fill',
    target: [MappingTypeEnum.AUTHORING, MappingTypeEnum.AI_AUTHORING],
  },
  link: { value: 'Link', target: [MappingTypeEnum.AUTHORING, MappingTypeEnum.AI_AUTHORING] },
  comment: { value: 'Comment', target: Object.values(MappingTypeEnum) },
  viewComments: { value: 'View Comments', target: Object.values(MappingTypeEnum) },
  imageTagging: { value: 'Image Tagging', target: Object.values(MappingTypeEnum) },
  reset: { value: 'Reset', target: [MappingTypeEnum.AUTHORING, MappingTypeEnum.AI_AUTHORING] },
};

export const exportOptions = {
  cleanDocument: {
    value: 'Clean Document',
    target: [MappingTypeEnum.AUTHORING, MappingTypeEnum.AI_AUTHORING],
  },
  annotatedDocument: {
    value: 'Annotated Document',
    target: [MappingTypeEnum.AUTHORING, MappingTypeEnum.AI_AUTHORING],
  },
};

export const groupedOptions = (
  mappingType: MappingTypeEnum,
  options: {
    isQuickFillEnabled: boolean;
    isLinkEnabled: boolean;
    isCommentEnabled: boolean;
    isImageTaggingEnabled: boolean;
  },
) => {
  const updatedActionOptions = {
    ...actionOptions,
    quickFill: {
      ...actionOptions.quickFill,
      disabled: !options.isQuickFillEnabled,
    },
    link: {
      ...actionOptions.link,
      disabled: !options.isLinkEnabled,
    },
    comment: {
      ...actionOptions.comment,
      disabled: !options.isCommentEnabled,
    },
    imageTagging: {
      ...actionOptions.imageTagging,
      disabled: !options.isImageTaggingEnabled,
    },
  };
  const gOptions = [
    {
      header: '',
      options: getGroupedOptions(updatedActionOptions, mappingType),
    },
    {
      header: 'Export',
      options: getGroupedOptions(exportOptions, mappingType),
    },
  ];
  return gOptions.filter((option) => option.options.length);
};
