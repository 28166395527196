import { useContext, useEffect, useState } from 'react';
import Button from '../elements/Button';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from '../../contexts/UserContext';
import BreadCrumbs from '../elements/BreadCrumbs';
import useClientInfo from '../../hooks/useClientInfo';
import UserTable from './UserTable';
import { Modal } from '../Modal';
import { CreateUserForm } from './CreateUserForm';
import DeleteUser from './DeleteUser';
import EditUserForm from './EditUserForm';
import { notifyError } from '../../helpers/utils';
import { fetchAllUserFromDB } from '../../services/apiCalls';
import { DBUser } from '../../types';
import Input from '../elements/Input';

const UserManagement = () => {
  const { isAdmin } = useClientInfo();
  const { email } = useContext(UserContext).userObj;

  const [userList, setUserList] = useState<DBUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<DBUser>();
  const [filteredUsers, setFilteredUsers] = useState<DBUser[]>(userList);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState<'Create' | 'Edit' | null>(null);
  const [userToDelete, setUserToDelete] = useState<DBUser | null>(null);

  const getAllUsers = async (): Promise<void> => {
    setLoading(true);
    const res: any = await fetchAllUserFromDB();
    if (!res.success) {
      setUserList([]);
      notifyError(res.message);
      return;
    }

    const users = res.data.users.map((user: Partial<DBUser>) => ({
      name: `${user.firstName} ${user.lastName}`,
      ...user,
    }));

    setUserList(users);
    setFilteredUsers(users);
    setLoading(false);
  };

  const handleSearch = (value: string) => {
    if (value) {
      setFilteredUsers(
        userList.filter(
          (user) =>
            user.name.toLowerCase().includes(value.toLowerCase()) ||
            user.email.toLowerCase().includes(value.toLowerCase()),
        ),
      );
    } else {
      setFilteredUsers(userList);
    }
  };

  const handleColumnSort = (order: 'ASC' | 'DESC', column: keyof DBUser) => {
    if (column === 'roles') return;

    if (order === 'ASC') {
      setFilteredUsers([...filteredUsers].sort((a, b) => a[column].localeCompare(b[column])));
    } else {
      setFilteredUsers([...filteredUsers].sort((a, b) => b[column].localeCompare(a[column])));
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <>
      <DeleteUser
        getAllUsers={getAllUsers}
        userObj={userToDelete}
        setUserToDelete={setUserToDelete}
      />
      <BreadCrumbs
        className="text-start ml-28 mt-8 mb-12 identifiers"
        data={[
          { link: '/', text: 'Home' },
          { text: 'Settings', clickable: false },
        ]}
      />

      <div className="w-10/12 mx-auto mt-5 text-right flex justify-between">
        <Input
          className="w-1/4"
          placeholder="Search"
          dataTestId="search-user"
          onChange={(e) => handleSearch(e.target.value)}
        />
        {isAdmin && (
          <Button
            onClick={() => setShowModal('Create')}
            title="+ Create User"
            className="px-3 py-2 bg-primary_bg_color hover:bg-secondary_bg_color text-white font-bold text-sm rounded-md"
            data-testid="create-user-button"
          />
        )}
      </div>
      <UserTable
        handleEdit={(user) => {
          setSelectedUser(user);
          setShowModal('Edit');
        }}
        userList={filteredUsers}
        handleShowDelete={isAdmin ? setUserToDelete : undefined}
        email={email}
        loading={loading}
        isAdmin={isAdmin}
        handleColumnSort={handleColumnSort}
      />
      <Modal
        modalIsOpen={showModal !== null}
        closeModal={() => setShowModal(null)}
        title={`${showModal} User`}>
        {}
        {showModal === 'Create' ? (
          <CreateUserForm closeModal={() => setShowModal(null)} getAllUsers={getAllUsers} />
        ) : (
          selectedUser && (
            <EditUserForm
              getAllUsers={getAllUsers}
              closeModal={() => setShowModal(null)}
              user={selectedUser}
            />
          )
        )}
      </Modal>
    </>
  );
};

export default UserManagement;
