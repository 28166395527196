import { createContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Dashboard from '../components/Dashboard';
import { FileUploader } from '../components/fileUploader';
import Navbar from '../components/navbar';
import NoPage from '../pages/NoPage';
import CatalogList from '../pages/catalog';
import CatalogContextContainer from '../contexts/CatalogContext';
import AutoMappingContextContainer from '../contexts/autoMappingContext';
import { convertFileToHTML } from '../helpers/convertFileType';
import CustomRoute from './CustomRoute';
import RolePermissions from '../pages/RolePermissions';
// import DocumentVerification from "../pages/viewer-screen/DocumentVerification";
import MappingContextContainer from '../contexts/MappingContext';
// import Scoring from "../pages/viewer-screen/Scoring";
// import CompletedDocuments from "../pages/completedDocuments";
import { useCompletedDocuments } from '../hooks/useCompletedDocuments';
// import MappingVerification from "../pages/viewer-screen/MappingVerification";
import GeneralizedDocuments from '../components/GeneralizedDocuments';
import GeneralizationContextContainer from '../contexts/GeneralizationContext';
import CatalogNotificationContextContainer from '../contexts/CatalogNotificationContext';
import UserContextContainer from '../contexts/UserContext';
import CatalogTemplateForm from '../pages/CatalogTemplateForm';
import AutoMappingScreen from '../pages/viewer-screen/AutoMappingScreen';
import AutoMappedPage from '../pages/AutoMappedPage';
import UserManagement from '../components/user/UserManagement';
export const Context = createContext({});
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ProjectSettings } from '../pages/ProjectSettings';
import UploadSource from '../pages/upload/UploadSource/UploadSource';
import Authoring from '../pages/authoring';
import DataVerification from '../pages/DataVerification';
import AddSource from '../pages/addSource';

const queryClient = new QueryClient();

const PrivateRoutes = () => {
  const RedirectToHomePageRoutes = ['/login', '/password'];
  const [singleFile, setSingleFile] = useState('');
  const [multipleFile, setMultipleFile] = useState([]);
  const [templateFile, setTemplateFile] = useState('');
  const [templateFileDisplay, setTemplateFileDisplay] = useState({});
  const autowritenDocuments = useCompletedDocuments({ mode: 'autowritten' });
  const manualAuthoredDocuments = useCompletedDocuments({ mode: 'manual' });
  const [isButtonDisable, setIsButtonDisable] = useState({
    singleFileSelected: true,
    multipleFileSelected: true,
    templateFileSelected: true,
  });

  const [showLoader, setShowLoader] = useState({
    singleFileLoader: false,
    multipleFileLoader: false,
    templateFileLoader: false,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const singleFileHandler = (event) => {
    const files = Object.values(event.target.files);
    if (files.length > 0) {
      setShowLoader({ ...showLoader, singleFileLoader: true });
      convertFileToHTML(files[0])
        .then((res) => {
          setSingleFile(res);
          setShowLoader({ ...showLoader, singleFileLoader: false });
          setIsButtonDisable({ ...isButtonDisable, singleFileSelected: false });
        })
        .catch((err) => {
          setShowLoader({ ...showLoader, singleFileLoader: false });
          console.error(err);
        });
    }
  };

  const templateFileHandler = (event) => {
    const files = Object.values(event.target.files);
    if (files.length > 0) {
      setShowLoader({ ...showLoader, templateFileLoader: true });
      convertFileToHTML(files[0])
        .then((res) => {
          setTemplateFile(res);
          setShowLoader({ ...showLoader, templateFileLoader: false });
          setIsButtonDisable({
            ...isButtonDisable,
            templateFileSelected: false,
          });
        })
        .catch((err) => {
          setShowLoader({ ...showLoader, singleFileLoader: false });
          console.error(err);
        });
    }
  };

  useEffect(() => {
    if (RedirectToHomePageRoutes.includes(location.pathname)) {
      navigate('/');
    }
  }, []);

  const setTemplateFileValue = (newValue) => {
    setTemplateFile((prevTemplateFile) => {
      return { ...prevTemplateFile, value: newValue };
    });
  };

  const setTemplateFileDisplayValue = (newValue) => {
    setTemplateFileDisplay((prevTemplateFile) => {
      return { ...prevTemplateFile, value: newValue };
    });
  };

  const setSingleFileValue = (newValue) => {
    setSingleFile((prevSingleFile) => {
      return { ...prevSingleFile, value: newValue };
    });
  };

  const routes = [
    {
      path: '/fileUploader',
      component: <FileUploader />,
    },
    {
      path: '/userManagement',
      component: <UserManagement />,
    },
    {
      path: '/rolePermission',
      component: <RolePermissions />,
    },
    {
      path: '/auto-mapping',
      component: <AutoMappingScreen />,
    },
    // Path '/list' is used for CatalogPage for now.
    // Commenting this out as '/list' is currently used for CatalogList.
    // This comment can be removed if '/list' is going to be used for CatalogPage in future.
    // {
    //   path: '/list',
    //   component: <CatalogPage />,
    // },
    // Temporary route for CatalogList - just to ensure that none of the previous links break.
    {
      path: '/list',
      component: <CatalogList />,
    },
    {
      path: '/catalog-list',
      component: <CatalogList />,
    },
    {
      path: '/auto-mapped',
      component: <AutoMappedPage />,
    },
    // {
    //   path: "/documents-list",
    //   component: <CompletedDocuments />
    // },
    // {
    //   path: "/documents-list/documents-verification",
    //   component: <DocumentVerification />
    // },
    // {
    //   path: "/documents-list/documents-scoring",
    //   component: <Scoring />
    // },
    // {
    //   path: "/documents-list/mapping-verification",
    //   component: <MappingVerification />
    // },
    {
      path: '/mapping-sessions',
      component: <GeneralizedDocuments />,
    },
    {
      path: '/project-settings',
      component: <ProjectSettings />,
    },
    {
      path: '/mapping-sessions/:id',
      component: <Authoring />,
    },
    {
      path: '/add-source',
      component: <AddSource />,
    },
    {
      path: '/mapping-sessions/:id/dv',
      component: <DataVerification />,
    },
    {
      path: '/catalog/new',
      component: <CatalogTemplateForm />,
    },
    {
      path: '/upload/source',
      component: <UploadSource />,
    },
  ];

  return (
    <Context.Provider
      value={{
        setMultipleFile,
        setSingleFile,
        setTemplateFile,
        multipleFile,
        singleFile,
        templateFile,
        templateFileDisplay,
        autowritenDocuments,
        manualAuthoredDocuments,
        showLoader,
        setShowLoader,
        setIsButtonDisable,
        isButtonDisable,
        templateFileHandler,
        singleFileHandler,
        setSingleFileValue,
        setTemplateFileValue,
        setTemplateFileDisplayValue,
        setTemplateFileDisplay,
      }}>
      <CatalogContextContainer>
        <MappingContextContainer>
          <GeneralizationContextContainer>
            <AutoMappingContextContainer>
              <UserContextContainer>
                <CatalogNotificationContextContainer>
                  <QueryClientProvider client={queryClient}>
                    <Routes>
                      <Route path="/" element={<Navbar />}>
                        <Route exact path="/" element={<Dashboard />} />
                        {routes.map((route) => {
                          return (
                            <Route
                              key={route.path}
                              path={route.path}
                              element={
                                <CustomRoute path={route.path}>{route.component}</CustomRoute>
                              }
                            />
                          );
                        })}
                      </Route>
                      <Route path="*" element={<NoPage />} />
                    </Routes>
                  </QueryClientProvider>
                </CatalogNotificationContextContainer>
              </UserContextContainer>
            </AutoMappingContextContainer>
          </GeneralizationContextContainer>
        </MappingContextContainer>
      </CatalogContextContainer>
    </Context.Provider>
  );
};
export default PrivateRoutes;
