import { FC } from 'react';
import { removeUserFromDB } from '../../services/apiCalls';
import { notifyError, notifySuccess } from '../../helpers/utils';
import { DBUser } from '../../types';
import { DeleteConfirmationDialogue } from '../modals/DeleteConfirmationDialogue';

interface IDeleteUser {
  userObj: DBUser | null;
  getAllUsers: () => void;
  setUserToDelete: (user: DBUser | null) => void;
}
export const DeleteUser: FC<IDeleteUser> = ({ userObj, getAllUsers, setUserToDelete }) => {
  const deleteUser = async () => {
    try {
      const res: any = await removeUserFromDB(userObj?.id);
      if (res?.success) {
        notifySuccess(res.data.message);
        getAllUsers();
      }
    } catch (err: any) {
      notifyError(err.message);
    }
  };

  return (
    <DeleteConfirmationDialogue
      isOpen={!!userObj}
      handleClose={() => setUserToDelete(null)}
      item="user"
      handleDelete={deleteUser}
    />
  );
};

export default DeleteUser;
