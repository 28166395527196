import { CatalogTemplateFormProps } from '../EditCatalogTemplate.types';

export const getInputList = (
  templateType: string,
): {
  label: string;
  name: keyof CatalogTemplateFormProps;
  type: string;
  disabled: boolean;
  placeholder?: number;
}[] => {
  switch (templateType) {
    case 'Automated':
      return [
        { label: 'Document Name', name: 'templateName', type: 'string', disabled: false },
        { label: 'Section', name: 'section', type: 'string', disabled: false },
        { label: 'Group', name: 'group', type: 'string', disabled: false },
        { label: 'Version', name: 'version', type: 'number', disabled: false, placeholder: 0.1 },
      ];
    default:
      return [{ label: 'Group', name: 'group', type: 'string', disabled: true }];
  }
};
