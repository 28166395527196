import React, { FC, ReactNode } from 'react';

type LayoutProps = {
  children: ReactNode;
};

export const Header: FC<{ children: ReactNode }> = ({ children }) => <>{children}</>;
export const Body: FC<{ children: ReactNode }> = ({ children }) => <>{children}</>;

type LayoutType = FC<LayoutProps> & {
  Header: typeof Header;
  Body: typeof Body;
};

const Layout: LayoutType = ({ children }) => {
  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          if (child.type === Header) {
            return <div>{child}</div>;
          }
          if (child.type === Body) {
            return (
              <div className="h-full pt-0 px-5 flex-grow pb-10">
                <div className="flex flex-col p-[2.5rem] items-start gap-[2.75rem] flex-1 basis-0 grow-0 bg-system-50 rounded-lg shadow-base h-full">
                  {child}
                </div>
              </div>
            );
          }
        }
        return child;
      })}
    </>
  );
};

Layout.Header = Header;
Layout.Body = Body;

export default Layout;
