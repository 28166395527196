import { useEffect, useState } from 'react';

const useFullscreen = ({ element }: { element?: HTMLElement | null }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const handleFullScreen = () => {
      setIsFullScreen((prev) => !prev);
    };
    document.addEventListener('fullscreenchange', handleFullScreen);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreen);
    };
  }, []);

  const toggleFullScreen = () => {
    if (element) {
      if (isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if ((document as any).webkitExitFullscreen) {
          (document as any).webkitExitFullscreen();
        } else if ((document as any).mozCancelFullScreen) {
          (document as any).mozCancelFullScreen();
        } else if ((document as any).msExitFullscreen) {
          (document as any).msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if ((element as any).webkitRequestFullscreen) {
          /* Safari */
          (element as any).webkitRequestFullscreen();
        } else if ((element as any).msRequestFullscreen) {
          /* IE11 */
          (element as any).msRequestFullscreen();
        }
      }
    }
  };

  return {
    toggleFullScreen,
    isFullScreen,
  };
};

export default useFullscreen;
