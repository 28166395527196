import { useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useEffect } from 'react';
import {
  addRolePermissions,
  getPermissions,
  getRolePermissions,
  getRoles,
} from '../services/apiCalls';
import { useNavigate } from 'react-router-dom';
import BreadCrumbs from '../components/elements/BreadCrumbs';

const RolePermissions = () => {
  const navigate = useNavigate();

  const [roles, setRoles] = useState([]);
  const [processedRoles, setProcessedRoles] = useState([]);
  const [rolesResponse, setRolesResponse] = useState({});
  const [currentRole, setCurrentRole] = useState('');
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    getRoles()
      .then((res) => {
        setRoles(res.data);
      })
      .catch((err) => console.error(err));
    getPermissions()
      .then((res) => {
        setPermissions(res.data);
      })
      .catch((err) => console.error(err));
  }, []);

  const roleChangeHandler = (rolesInput) => {
    setCurrentRole(rolesInput[0].name);
    setProcessedRoles((prevState) => [...prevState, rolesInput[0].name]);
    fetchRolePermissions(rolesInput[0].name);
  };

  const permissionChangeHandler = (permissionInput) => {
    const reqPermissions = permissionInput.filter(
      (permission) => !Object.keys(permission).includes('id'),
    );
    const actualPermissions = permissionInput.filter((permission) =>
      Object.keys(permission).includes('id'),
    );
    const reqPermissionsData = reqPermissions.map((permission) =>
      permissions.filter((el) => el.name === permission.name),
    );
    const updatedResponse = reqPermissionsData.map((permission) => permission[0]);

    const requiredResponse = [...actualPermissions, ...updatedResponse];
    rolesResponse[currentRole] = requiredResponse;
  };

  const fetchRolePermissions = (role) => {
    if (!processedRoles.includes(role)) {
      getRolePermissions(role)
        .then((res) => {
          setRolesResponse((prevState) => ({ ...prevState, [role]: res.data }));
        })
        .catch((err) => console.error(err));
    }
  };

  const updatePermissions = (e) => {
    e.preventDefault();
    addRolePermissions({ roles: roles, permissions: rolesResponse })
      .then((_res) => {
        navigate('/');
      })
      .catch((err) => console.error(err));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <>
      <BreadCrumbs
        className="text-start ml-28 mt-8 mb-12 identifiers"
        data={[
          { link: '/', text: 'Home' },
          { text: 'Permissions', clickable: false },
        ]}
      />
      <form className="mx-auto w-1/4 text-center" onSubmit={updatePermissions}>
        <h1 className="mb-5 mt-5 text-primary_text_color font-bold text-xl">Manage Permissions</h1>
        <div className="flex w-full justify-center items-center space-x-2">
          <div className="flex justify-center mt-5 px-6 py-8 mx-auto md:h-9/12 lg:py-0 relative">
            <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-white border-gray-200">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8 inputParent">
                <h1 className="font-bold leading-tight tracking-tight text-primary_text_color">
                  Roles
                </h1>
                <div className="space-y-4 md:space-y-6">
                  <Multiselect
                    options={roles.map((option) => ({
                      name: option.name,
                    }))}
                    onSelect={roleChangeHandler}
                    onRemove={roleChangeHandler}
                    onKeyPressFn={(e) => {
                      e.preventDefault();
                    }}
                    displayValue="name"
                    showCheckbox="true"
                    singleSelect={true}
                    placeholder="Select Roles"
                  />
                </div>
                <h1 className="font-bold leading-tight tracking-tight text-primary_text_color">
                  Permissions
                </h1>
                <Multiselect
                  options={permissions.map((option) => ({
                    name: option.name,
                  }))}
                  onSelect={permissionChangeHandler}
                  onRemove={permissionChangeHandler}
                  onKeyPressFn={handleKeyDown}
                  selectedValues={rolesResponse[currentRole]}
                  displayValue="name"
                  showCheckbox="true"
                  placeholder="Select Permissions"
                  className="permissionsClass mb-7"
                  disable={currentRole == ''}
                />
              </div>
            </div>
          </div>
        </div>
        <input
          type="submit"
          onSubmit={updatePermissions}
          title="Update"
          className="mt-10 font-bold w-40 text-white bg-primary_bg_color rounded-lg text-sm px-5 py-2.5 text-center hover:bg-secondary_bg_color"
        />
      </form>
    </>
  );
};

export default RolePermissions;
