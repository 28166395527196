import {
  Button,
  Header,
  TableHeader,
  Input,
  Dropzone,
} from '@gloabal-regulatory-writing-consulting/gxt-components';
import { QueryClient } from '@tanstack/react-query';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useState, useMemo, useContext, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import SvgIcon from '../../../components/elements/SvgIcon';
import Layout from '../../../components/layout';
import { PaginatedTable } from '../../../components/PaginatedTable';
import { CatalogContext } from '../../../contexts/CatalogContext';
import { notifyError } from '../../../helpers/utils';
import { useCatalog } from '../../../services/api';
import { ColumnType, InputProps, SourceFile } from './UploadSource.type';
import { tableValueFormatter } from '../../../helpers/tableFormatter';

const MAX_FILES = 20;

const UploadSource = () => {
  const [files, setFiles] = useState<SourceFile[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPerPage, setCurrentPerPage] = useState(10);

  const { setCatalogFilesUploadStatus } = useContext(CatalogContext);
  const navigate = useNavigate();
  const columnsHelper = createColumnHelper<ColumnType>();

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, info: CellContext<ColumnType, any>) => {
    const { name, value } = e.target;
    const fileId = info.row.original.id;

    setFiles((prevFiles) => {
      return prevFiles.map((file) => {
        if (file.id === fileId) {
          return { ...file, [name]: value };
        }
        return file;
      });
    });
  };

  const getColumn = ({ name, title, required = false, size = 50, type = 'text' }: InputProps) => ({
    header: () => (
      <TableHeader handleColumnSort={() => {}} Title={required ? `${title}*` : title} />
    ),
    cell: (info: CellContext<ColumnType, any>) => (
      <Input
        className="invalid:border-negative-200"
        key={info.row.original.id}
        value={tableValueFormatter(info)}
        step={0.1}
        type={type}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnChange(e, info)}
        name={name}
        required={required}
      />
    ),
    size: size,
  });

  const columns = useMemo(
    () => [
      columnsHelper.accessor(
        'title',
        getColumn({ name: 'title', title: 'Name', required: true, size: 200 }),
      ),
      columnsHelper.accessor(
        'version',
        getColumn({ name: 'version', title: 'Version', required: true, type: 'number' }),
      ),
      columnsHelper.accessor(
        'project',
        getColumn({ name: 'project', title: 'Project', required: true }),
      ),
      columnsHelper.accessor('group', getColumn({ name: 'group', title: 'Group', required: true })),
      columnsHelper.accessor('site', getColumn({ name: 'site', title: 'Site' })),
      columnsHelper.accessor('sourceType', getColumn({ name: 'sourceType', title: 'Source Type' })),
      columnsHelper.accessor('id', {
        header: () => <TableHeader handleColumnSort={() => {}} Title="" />,
        cell: (info: CellContext<ColumnType, any>) => (
          <div
            className="flex justify-center items-center cursor-pointer"
            onClick={() => handleDelete(info.row.original.id)}>
            <SvgIcon iconType="delete" />
          </div>
        ),
      }),
    ],
    [],
  );

  const handleOnDrop = (newFiles: File[]) => {
    if (newFiles.length > MAX_FILES) {
      notifyError(`You can upload a maximum of ${MAX_FILES} files`);
      return;
    }
    const updatedFiles: SourceFile[] = newFiles.map((file) => ({
      id: v4(),
      title: file.name,
      version: 1.0,
      sourceType: 'source',
      group: '',
      project: '',
      site: '',
      file: file,
    }));
    const totalFiles = updatedFiles.length + files.length;
    if (totalFiles > MAX_FILES) {
      notifyError(`You can upload a maximum of ${MAX_FILES} files`);
      setFiles((prev) => [...prev, ...updatedFiles].slice(0, MAX_FILES));
    } else {
      setFiles((prev) => [...prev, ...updatedFiles]);
    }
  };

  const handleSelectAll = (allChecked: boolean, files: ColumnType[]) => {
    if (!allChecked) {
      setSelectedFiles(files.map((file) => file.id));
    } else {
      setSelectedFiles([]);
    }
  };

  const handleOnSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    if (checked) {
      setSelectedFiles([...selectedFiles, value]);
    } else {
      setSelectedFiles(selectedFiles.filter((id) => id !== value));
    }
  };

  const validFiles = useMemo(
    () =>
      files.length > 0 &&
      files.every(({ title, version, project, group }) => title && version && project && group),
    [files],
  );

  const queryClient = new QueryClient();
  const { addToCatalog } = useCatalog();

  const handleOnSubmit = () => {
    setCatalogFilesUploadStatus((prev) => prev.filter((status) => status.status === 'pending'));
    const promises = files.map((file) => {
      setCatalogFilesUploadStatus((prev) => [
        ...prev,
        { id: file.id, status: 'pending', title: file.title },
      ]);
      const formData = new FormData();
      for (const key in file) {
        formData.append(key, file[key as keyof SourceFile] as string | Blob);
      }
      return addToCatalog
        .mutateAsync(formData)
        .then(() => {
          setCatalogFilesUploadStatus((prev) =>
            prev.map((status) =>
              status.id === file.id ? { ...status, status: 'success' } : status,
            ),
          );
        })
        .catch(() => {
          setCatalogFilesUploadStatus((prev) =>
            prev.map((status) => (status.id === file.id ? { ...status, status: 'error' } : status)),
          );
        });
    });
    Promise.all(promises).then(() => {
      queryClient.invalidateQueries({ queryKey: ['Catalog'] });
    });
    navigateToSourceDocuments();
  };

  const navigateToSourceDocuments = () => {
    navigate('/catalog-list?documentType=Source&activeTab=Source');
  };

  const handleDelete = (id: string) => {
    setFiles((prev) => prev.filter((file) => file.id !== id));
    setSelectedFiles((prev) => prev.filter((fileId) => fileId !== id));
  };

  return (
    <>
      <Layout>
        <Layout.Header>
          <Header>
            <Header.Heading>Catalog</Header.Heading>
            <Header.Actions>
              <Button variant="secondary" disabled={!validFiles} onClick={handleOnSubmit}>
                Add to Catalog
              </Button>
              <Button variant="secondary" onClick={navigateToSourceDocuments}>
                Cancel
              </Button>
            </Header.Actions>
          </Header>
        </Layout.Header>
        <Layout.Body>
          <div className="flex justify-between items-center self-stretch">
            <p className="text-primary-300">Upload a maximum of {MAX_FILES} files at a time</p>
            <p className="text-primary-200 font-normal">Items Marked with a * are required</p>
          </div>
          <div className="w-full h-20">
            <Dropzone maxFiles={MAX_FILES} onDrop={handleOnDrop} />
          </div>
          <PaginatedTable<ColumnType>
            additionalColumns={columns}
            menuItems={[]}
            paginatedFilteredData={{ pagination: { page: currentPage, perPage: currentPerPage } }}
            getData={(() => {}) as any}
            clientSideData={files}
            clientSidePagination={true}
            handleSelectAll={handleSelectAll}
            onSelect={handleOnSelect}
            selectedData={selectedFiles}
            handlePageChange={setCurrentPage}
            handlePerPageChange={setCurrentPerPage}
          />
        </Layout.Body>
      </Layout>
    </>
  );
};

export default UploadSource;
